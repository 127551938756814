import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBulletList = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.499 5.364h9.17M16.67 10H7.499M7.499 14.636h9.17M3.748 4.947a.417.417 0 1 0 0 .834.417.417 0 0 0 0-.834M3.748 9.583a.417.417 0 1 0 0 .834.417.417 0 0 0 0-.834M3.748 14.22a.417.417 0 1 0 0 .833.417.417 0 0 0 0-.834"
      />
    </svg>
  );
};
export default SvgBulletList;
