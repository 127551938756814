import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type AppointmentDashboardCompletedAppointmentsSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { trackEvent } from "@/lib/tracking";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { distributeValuesAsPercentages } from "@/lib/math";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";

export const AppointmentDashboardCompletedAppointmentsSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment AppointmentDashboardCompletedAppointmentsSectionQueryFragment on DataItemRelatorioIndependente {
      mes
      consultasRealizadas
      consultasPresenciais
      consultasVirtuais
    }
  `,
);

interface AppointmentDashboardCompletedAppointmentsSectionProps {
  data: FragmentType<
    typeof AppointmentDashboardCompletedAppointmentsSectionQueryFragment
  >[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardCompletedAppointmentsSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardCompletedAppointmentsSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    AppointmentDashboardCompletedAppointmentsSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const { flags } = useRouteContext({ strict: false });

  const shouldShowCompletedAppointmentsSession =
    flags["exibe-secao-consultas-realizadas"];

  if (!shouldShowCompletedAppointmentsSession) {
    return null;
  }

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCompletedAppointmentsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Consultas realizadas",
      });

      void router.navigate({ search });
    } else if (searchParams.showCompletedAppointmentsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.consultasRealizadas !== 0,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Consultas realizadas
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={Number(currentMonthData?.consultasRealizadas ?? 0)}
          />
          <AppointmentDashboardCompletedAppointmentsSectionChart
            chartData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showCompletedAppointmentsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Consultas realizadas"
      >
        <p>
          Este gráfico apresenta as consultas realizadas no período selecionado, divididas
          em:
        </p>
        <ul className="text-md list-disc list-inside gap-1">
          <li className="list-item">
            <span className="font-bold ">Consultas na Livance: </span>
            <span>
              realizadas em uma de nossas unidades, incluindo salas de teleatendimento.
            </span>
          </li>
          <li className="list-item">
            <span className="font-bold ">Consultas virtuais: </span>
            <span>
              realizadas utilizando o consultório virtual fora de nossas unidades.
            </span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardCompletedAppointmentsSectionChartProps {
  chartData: readonly AppointmentDashboardCompletedAppointmentsSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardCompletedAppointmentsSectionChart = ({
  chartData,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardCompletedAppointmentsSectionChartProps): JSX.Element => {
  const data = chartData.map(({ consultasPresenciais, consultasVirtuais, mes }) => ({
    "Consultas na Livance": consultasPresenciais as number,
    "Consultas virtuais": consultasVirtuais as number,
    mes,
  }));

  const labels = data.map((entry) => {
    const values = [
      entry["Consultas na Livance"],
      entry["Consultas virtuais"],
    ] as number[];
    const percentages = distributeValuesAsPercentages(values);

    return {
      mes: entry.mes,
      "Consultas na Livance":
        percentages[0] >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${String(percentages[0])}%`
          : "",
      "Consultas virtuais":
        percentages[1] >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${String(percentages[1])}%`
          : "",
    };
  }) as Record<string, string>[];

  return (
    <div data-testid="completed-appointments-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem
            dataKey="Consultas na Livance"
            fill="#FFD094"
            disabledFill="#EDF0F2"
          />
          <ChartBarItem
            dataKey="Consultas virtuais"
            fill="#E59112"
            disabledFill="#D5DADF"
          />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
