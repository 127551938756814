import { CpsSpinner, CpsToggle } from "corpus";
import { ExclamationCircle } from "@repo/icons";
import { useState } from "react";
import { useRouteContext } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { DisabledFeatureQueryDocument } from "@repo/graphql-types/graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { type AssistFeatures } from "@/enums/assist-features";
import {
  useGraphQL,
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

const DisabledFeatureQuery = graphql(`
  query DisabledFeatureQuery {
    funcionalidadesDesativadas {
      codFuncionalidade
      codUsuario
    }
  }
`);

const DisableFeatureMutation = graphql(`
  mutation DisableFeatureMutation($codUsuario: Int!, $codFuncionalidade: Int!) {
    insert_funcionalidadesDesativadas_one(
      object: { codUsuario: $codUsuario, codFuncionalidade: $codFuncionalidade }
    ) {
      codUsuario
    }
  }
`);

const EnableFeatureMutation = graphql(`
  mutation EnableFeatureMutation($codUsuario: Int!, $codFuncionalidade: Int!) {
    delete_funcionalidadesDesativadas(
      where: {
        codUsuario: { _eq: $codUsuario }
        codFuncionalidade: { _eq: $codFuncionalidade }
      }
    ) {
      affected_rows
    }
  }
`);

export interface ToggleActionProps {
  featureId: AssistFeatures;
  title: string;
}

export const FeatureActivateToggle = ({
  featureId,
  title,
}: ToggleActionProps): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const { data, isPending } = useGraphQL(DisabledFeatureQuery);

  const { mutateAsync: mutateDisableAsync, isPending: isDisabling } =
    useGraphQLMutationWithErrorHandler(DisableFeatureMutation);

  const invalidateActiveAllocationDetailsQuery = useInvalidateQuery(
    DisabledFeatureQueryDocument,
  );

  const { mutateAsync: mutateEnableAsync, isPending: isEnabling } =
    useGraphQLMutationWithErrorHandler(EnableFeatureMutation);

  const [confirmationDrawerOpen, setConfirmationDrawerOpen] = useState(false);

  const onToggleChange = (value: boolean): void => {
    const mutateInput = {
      codUsuario: user.codUsuario,
      codFuncionalidade: featureId.valueOf(),
    };

    const onSuccess = (): void => {
      invalidateActiveAllocationDetailsQuery();
      setConfirmationDrawerOpen(false);
    };

    if (value) {
      void mutateDisableAsync(mutateInput, { onSuccess });
    } else {
      void mutateEnableAsync(mutateInput, { onSuccess });
    }
  };

  if (isPending) {
    return (
      <div className="bg-neutral-100 rounded-40 px-4 py-5 flex flex-row justify-between items-center">
        <p className="font-medium text-sm">Recuperação de agendamentos</p>
        <CpsSpinner small />
      </div>
    );
  }

  const isFeatureDisabled = data?.funcionalidadesDesativadas.some(
    (feature) => feature.codFuncionalidade === featureId.valueOf(),
  );

  return (
    <>
      <div className="bg-neutral-100 rounded-40 px-4 py-5 flex flex-row justify-between items-center">
        <p className="font-medium text-sm">{title}</p>
        <CpsToggle
          checked={!isFeatureDisabled}
          onChange={() => {
            setConfirmationDrawerOpen(true);
          }}
        />
      </div>
      <MessageDrawerRoot
        variant="secondary"
        icon={ExclamationCircle}
        open={confirmationDrawerOpen}
        setOpen={setConfirmationDrawerOpen}
      >
        <MessageDrawerTitle>{title}</MessageDrawerTitle>
        <MessageDrawerBody>
          {!isFeatureDisabled ? (
            <>
              <p>
                Nossas mensagens ajudam a aumentar seus agendamentos. Ao desativá-las,
                você pode perder oportunidades importantes.
              </p>
              <br />
              <p>Tem certeza que deseja desligar?</p>
            </>
          ) : (
            <p>
              Nossas mensagens te ajudam a aumentar seus agendamentos. Ative-as e não
              perca oportunidades.
            </p>
          )}
        </MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            loading={isDisabling || isEnabling}
            onClick={() => {
              onToggleChange(!isFeatureDisabled);
            }}
          >
            {!isFeatureDisabled ? "Desativar mensagens" : "Ativar mensagens"}
          </MessageDrawerActionButton>
          <MessageDrawerActionButton
            onClick={() => setConfirmationDrawerOpen(false)}
            secondary
          >
            Cancelar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
    </>
  );
};
