import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import { Chart, BulletList, HelpCircle, Left } from "@repo/icons";
import {
  zonedDate,
  getDateFromToday,
  formatDateAndTimeIgnoringTimezone,
} from "@repo/lib";
import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { CpsTab, CpsTabList } from "corpus";
import { useAtom } from "jotai";
import { useGraphQL } from "@/hooks/use-graphql";
import { Page } from "@/components/page";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { selectedTabAtom } from "@/lib/atoms/payments-atom";
import { PaymentsTabs } from "@/enums/payments-tabs";
import { PatientPaymentListTab } from "@/components/patient-payments-list-tab";

const PatientsPaymentsQuery = graphql(`
  query PatientsPaymentsQuery($intervalStart: String!, $intervalEnd: String!) {
    patientPayments: ExpensumGetPayments(
      arg1: { intervalStart: $intervalStart, intervalEnd: $intervalEnd }
    ) {
      totalAmount
      ...PatientPaymentsListFragment
    }
  }
`);

const today = zonedDate(new Date());
const last7Days = getDateFromToday(-6); // menos um dia pois conta o dia de hoje

const patientPaymentsPageSearchParams = z.object({
  back: z.number().optional(),
  filter: z.boolean().optional(),
  about: z.boolean().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const PatientPaymentsPage = (): JSX.Element => {
  const [selectedTabAtomValue, setSelectedTabAtomValue] = useAtom(selectedTabAtom);
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/",
  });
  const { flags } = useRouteContext({ strict: false });
  const showTabsAndCharts = flags["exibe-graficos-consolidados-em-pagamentos"];

  const buildInterval = (): { intervalStart: string; intervalEnd: string } => {
    const intervalStart = searchParams.startDate ?? searchParams.endDate ?? last7Days;
    const intervalEnd = searchParams.endDate ?? searchParams.startDate ?? today;

    return {
      intervalStart: formatDateAndTimeIgnoringTimezone(
        intervalStart,
        undefined,
        "yyyy-MM-dd",
      ),
      intervalEnd: formatDateAndTimeIgnoringTimezone(
        intervalEnd,
        undefined,
        "yyyy-MM-dd",
      ),
    };
  };

  const { intervalStart, intervalEnd } = buildInterval();

  const router = useRouter();

  const queryData = useGraphQL(PatientsPaymentsQuery, {
    intervalStart,
    intervalEnd,
  });

  const { data } = queryData;

  const navigateBack = (): void => {
    const backSteps = searchParams.back ?? 0;
    const historySteps = -1 - backSteps;

    router.history.go(historySteps);
  };

  const handleClickAbout = (): void => {
    void router.navigate({ to: "/settings/payments/patient-payments/about-payments" });
  };

  const selectedTabComponentDictionary = {
    [PaymentsTabs.Charts]: "Componente de graficos aqui",
    [PaymentsTabs.List]: (
      <PatientPaymentListTab
        data={data}
        intervalStart={intervalStart}
        intervalEnd={intervalEnd}
      />
    ),
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={navigateBack} icon={Left} align="start" />
        <HeaderTitle title="Pagamentos" />
        <HeaderButton align="end" icon={HelpCircle} onClick={handleClickAbout} />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryData}>
          {showTabsAndCharts ? (
            <CpsTabList
              selectedTabIndex={selectedTabAtomValue}
              setSelected={(index) => setSelectedTabAtomValue(index)}
              className="justify-center mb-4"
            >
              <CpsTab Icon={Chart}>Gráfico</CpsTab>
              <CpsTab Icon={BulletList}>Lista</CpsTab>
            </CpsTabList>
          ) : null}

          {showTabsAndCharts ? (
            selectedTabComponentDictionary[selectedTabAtomValue]
          ) : (
            <PatientPaymentListTab
              data={data}
              intervalStart={intervalStart}
              intervalEnd={intervalEnd}
            />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/")({
  component: PatientPaymentsPage,
  validateSearch: patientPaymentsPageSearchParams,
});
