import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { RescuedUnscheduledAppointmentsSection } from "@/components/rescued-appointment-overview/rescued-unscheduled-appointments-section";
import { RescuedCancelledAppointmentsSection } from "@/components/rescued-appointment-overview/rescued-cancelled-appointments-section";
import { RescuedNoShowAppointmentsSection } from "@/components/rescued-appointment-overview/rescued-no-show-appointments-section";
import { RescuedAppointmentsType } from "@/enums/rescued-appointments-types";
import { TotalRescuedAppointmentsSection } from "@/components/rescued-appointment-overview/total-rescued-appointments-section";
import { AssistFeatures } from "@/enums/assist-features";
import { FeatureActivateToggle } from "@/components/feature-activate-toggle";

export const RescuedAppointmentsSectionQueryFragment = graphql(/* GraphQL */ `
  fragment RescuedAppointmentsSectionQueryFragment on AnalyticsMembroTotalDeAgendamentosResgatadosPorFluxo {
    data {
      month: mes
      rescuedAppointmentType: fluxo
      appointmentsRescuedSum: agendamentosRecuperados
      sentMessagesSum: totalMensagens
    }
  }
`);

interface TotalRescuedAppointmentsSectionData {
  appointmentsRescuedSum: number;
}

interface RescuedAppointmentsSectionData extends TotalRescuedAppointmentsSectionData {
  month: string;
  rescuedAppointmentType: RescuedAppointmentsType;
  sentMessagesSum: number;
}

interface CustomerContactDashboardRescuedAppointmentsSectionProps {
  fragmentData: FragmentType<typeof RescuedAppointmentsSectionQueryFragment>;
  selectedMonth: string;
}

export const CustomerContactDashboardRescuedAppointmentsSection = ({
  fragmentData,
  selectedMonth,
}: CustomerContactDashboardRescuedAppointmentsSectionProps): JSX.Element => {
  const { data: rescuedAppointmentsData } = useFragment(
    RescuedAppointmentsSectionQueryFragment,
    fragmentData,
  );

  const filteredData = rescuedAppointmentsData.filter(
    (item) => item?.month === selectedMonth,
  );

  const noShowAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.NoShow,
  );

  const cancelledAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.Cancelled,
  );

  const unscheduledAppointment = filteredData.find(
    (item) => item?.rescuedAppointmentType === RescuedAppointmentsType.Unscheduled,
  );

  const totalRescuedData = [
    noShowAppointment,
    cancelledAppointment,
    unscheduledAppointment,
  ]
    .filter((item): item is RescuedAppointmentsSectionData => Boolean(item))
    .reduce((sum, item) => sum + (item.appointmentsRescuedSum), 0);
  
  const totalAppointmentsRescuedSum = { appointmentsRescuedSum: totalRescuedData};

  return (
    <div className="flex flex-col gap-8">
      <TotalRescuedAppointmentsSection data={totalAppointmentsRescuedSum} />
      <div className="pb-4">
        <RescuedUnscheduledAppointmentsSection
          data={unscheduledAppointment as RescuedAppointmentsSectionData}
        />
        <FeatureActivateToggle
          title="Recuperação de agendamentos"
          featureId={AssistFeatures.RescueLeftScheduling}
        />
      </div>
      <div className="pb-4">
        <RescuedCancelledAppointmentsSection
          data={cancelledAppointment as RescuedAppointmentsSectionData}
        />
        <FeatureActivateToggle
          title="Recuperação de cancelamentos"
          featureId={AssistFeatures.RescueCancelAppointment}
        />
      </div>
      <div className="pb-4">
        <RescuedNoShowAppointmentsSection
          data={noShowAppointment as RescuedAppointmentsSectionData}
        />
        <FeatureActivateToggle
          title="Recuperação de faltas"
          featureId={AssistFeatures.RescueNoShowAppointment}
        />
      </div>
    </div>
  );
};
