import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { SelectInput, type SelectInputProps } from "@/components/select-input";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { useGraphQL } from "@/hooks/use-graphql";

export const LinkDestinationSelectInputQuery = graphql(/* GraphQL */ `
  query LinkDestinationSelectInputQuery {
    linksDestinos {
      nome
      codLinkDestino
    }
  }
`);

export const LinkDestinationSelectInput = forwardRef<
  HTMLInputElement,
  Omit<SelectInputProps<number>, "items">
>(({ ...props }, _ref: React.Ref<HTMLInputElement>): JSX.Element => {
  const queryData = useGraphQL(LinkDestinationSelectInputQuery);

  const { data } = queryData;

  const items: SelectDrawerItemProps<number>[] =
    data?.linksDestinos.map((destination) => {
      return {
        label: destination.nome,
        value: destination.codLinkDestino,
      };
    }) ?? [];

  return <SelectInput<number> {...props} items={items} />;
});

LinkDestinationSelectInput.displayName = "LinkDestinationSelectInput";
