import { Clock, Phone, Left, Smile, Doctor, Link1, UserRefresh } from "@repo/icons";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { HeaderUpButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { Page } from "@/components/page";
import { AssistFeatures } from "@/enums/assist-features";
import { AssistProBadge } from "@/routes/settings/assist/-components/assist-pro-badge.tsx";

export const LivanceAssistMenuPage = (): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });

  const showPRM = user.funcionalidadesContratadas.some(
    (item) => item.codFuncionalidade === AssistFeatures.PRM.valueOf() && item.ativo,
  );

  const showCustomerReport = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === AssistFeatures.CustomerContactOverview.valueOf() &&
      item.ativo,
  );

  const showAppointmentReport = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === AssistFeatures.CustomerAppointmentOverview.valueOf() &&
      item.ativo,
  );

  const showPersonalizeAppointment = user.funcionalidadesContratadas.some(
    (item) =>
      item.codFuncionalidade === AssistFeatures.PersonalizeAppointment.valueOf() &&
      item.ativo,
  );

  const showRescueAppointment = user.funcionalidadesContratadas.some(
    (item) =>
      (item.codFuncionalidade === AssistFeatures.RescueLeftScheduling.valueOf() &&
        item.ativo) ||
      (item.codFuncionalidade === AssistFeatures.RescueCancelAppointment.valueOf() &&
        item.ativo) ||
      (item.codFuncionalidade === AssistFeatures.RescueNoShowAppointment.valueOf() &&
        item.ativo),
  );

  const menuItens: (MenuListItem & { show: boolean })[] = [
    {
      name: "Relatório de consultas",
      Icon: Doctor,
      route: "/settings/assist/customer-appointment-overview",
      show: showAppointmentReport,
      description: "Monitore o fluxo de consultas agendadas e realizadas.",
    },
    {
      name: "Relatório de contatos",
      Icon: Phone,
      route: "/settings/assist/customer-contact-overview",
      show: showCustomerReport,
      tag: <AssistProBadge />,
      description: "Acompanhe o progresso dos contatos do seu consultório.",
    },
    {
      name: "Links com rastreamento",
      Icon: Link1,
      route: "/settings/assist/user-trackable-links",
      description:
        "Crie links com rastreamento para monitorar os acessos e medir a performance das suas divulgações.",
      show: true,
    },
    {
      name: "Lembretes programados",
      Icon: Clock,
      route: "/settings/assist/follow-up-appointment-reminder",
      description: "Veja os pacientes com lembretes de retorno programados por você.",
      show: showPRM,
      tag: <AssistProBadge />,
    },
    {
      name: "Personalizar atendimento",
      Icon: Smile,
      route: "/settings/assist/secretary-details",
      description:
        "Forneça informações para as secretárias atenderem de forma personalizada e agendarem mais.",
      show: showPersonalizeAppointment,
      tag: <AssistProBadge />,
    },
    {
      name: "Recuperação de agendamentos",
      Icon: UserRefresh,
      route: "/settings/assist/rescued-appointment-overview",
      description:
        "Automatize mensagens para recuperar consultas canceladas ou perdidas de forma prática.",
      show: showRescueAppointment,
      tag: <AssistProBadge />,
    },
  ];

  const activeMenuItens = menuItens.filter((item) => item.show);

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Livance Assist" />
      </HeaderRoot>
      <Page className="pt-2">
        <MenuList menuItems={activeMenuItens} />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/")({
  component: LivanceAssistMenuPage,
});
