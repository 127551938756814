import { type SVGIconProps } from "@repo/icons";
import { clsx } from "clsx";
import React, { type ComponentPropsWithoutRef, type FunctionComponent } from "react";

export interface ICpsTab extends ComponentPropsWithoutRef<"button"> {
  selected?: boolean;
  Icon?: FunctionComponent<SVGIconProps>;
  children: string;
}

/**
 * Tab component
 *
 *
 * @example
 * ```tsx
 * <CpsTab Icon={User1} selected>
 *   Minha conta
 * </CpsTab>
 * ```
 * @param Icon - The icon of the tab.
 * @param selected - Whether the tab is selected.
 * @param children - The text of the tab.
 * @param disabled - Whether the tab is disabled.
 * @param props - Button props
 */
export const CpsTab = ({
  selected,
  Icon,
  disabled,
  children,
  ...props
}: ICpsTab): React.JSX.Element => {
  const commonClasses =
    "hover:after:bg-primary-300 group text-sm font-medium text-neutral-500 transition-all duration-200 after:absolute after:bottom-0 after:h-[2px] after:w-full after:transition-all after:duration-200 hover:text-neutral-700 hover:after:h-1 focus:text-neutral-700 disabled:text-neutral-200 disabled:after:bg-neutral-100";
  const selectedClasses = "after:bg-primary-300 text-neutral-700 after:h-1";

  const getIconFillColor = (): string => {
    if (disabled) return "fill-neutral-200";

    if (selected) return "fill-neutral-700";

    return "fill-neutral-500";
  };

  const cpsTabClasses = clsx(
    "px-6 relative flex w-fit items-center justify-center whitespace-pre py-4",
    commonClasses,
    selected ? selectedClasses : "after:bg-neutral-200",
    disabled && "pointer-events-none",
  );

  const iconClasses = clsx(
    `mr-2 shrink-0 transition-all duration-200 ${getIconFillColor()}`,
    disabled ? "group-hover:fill-neutral-200" : "group-hover:fill-neutral-700",
  );

  return (
    <button disabled={disabled} className={cpsTabClasses} {...props}>
      {Icon ? <Icon size={24} className={iconClasses} /> : null}

      {children}
    </button>
  );
};

export default CpsTab;
