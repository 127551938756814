"use client";

import {
  CheckCircle,
  ExclamationCircle,
  InformationCircle,
  type SVGIconProps,
  XCircle,
} from "@repo/icons";
import { default as clsx } from "clsx";
import { type FunctionComponent } from "react";

export interface CpsAlertProps {
  Icon?: FunctionComponent<SVGIconProps>;
  title?: string;
  description?: string;
  type: "success" | "danger" | "info" | "warning";
  link?: string;
  linkTitle?: string;
  linkPosition?: "bottom" | "right";
  linkProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
}

const styles = {
  success: {
    icon: <CheckCircle className="fill-success-700" size={24} />,
    bg: "bg-success-50",
    text: "text-success-700",
    fill: "fill-success-700",
  },
  danger: {
    icon: <XCircle className="fill-danger-700" size={24} />,
    bg: "bg-danger-50",
    text: "text-danger-700",
    fill: "fill-danger-700",
  },
  info: {
    icon: <InformationCircle className="fill-secondary-500" size={24} />,
    bg: "bg-secondary-50",
    text: "text-secondary-500",
    fill: "fill-secondary-500",
  },
  warning: {
    icon: <ExclamationCircle className="fill-warning-700" size={24} />,
    bg: "bg-warning-50",
    text: "text-warning-700",
    fill: "fill-warning-700",
  },
};

/**
 * Renderiza um banner com título, descrição e link opcional com base nas props.
 * Aplica estilos com base na prop type.
 *
 * @param Icon - Ícone a ser renderizado no lado esquerdo do alerta
 * @param title - Título para o alerta
 * @param description - Descrição para o alerta
 * @param type - Tipo de alerta (success, warning, error, info)
 * @param link - Link opcional para o alerta
 * @param linkTitle - Texto opcional para o link
 * @param linkPosition - Posição do link (right, bottom)
 * @param linkProps - Props opcionais para o link
 */
export const CpsAlert = ({
  Icon,
  title,
  description,
  type,
  link,
  linkTitle = "Ver detalhes",
  linkPosition = "right",
  linkProps,
}: CpsAlertProps) => {
  const renderLink = (): JSX.Element | null => {
    if (!link && !linkProps?.onClick) {
      return null;
    }

    return (
      <div
        className={clsx(
          "shrink-0 text-sm font-medium underline",
          linkPosition === "right" ? "ml-8" : "mt-4",
          styles[type].text,
        )}
      >
        {" "}
        <a {...linkProps} href={link}>
          {linkTitle}
        </a>{" "}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "flex w-full items-center justify-between rounded-md p-4",
        styles[type].bg,
      )}
    >
      <div className="flex items-center">
        <div className="h-6 w-6">
          {Icon ? <Icon size={24} className={styles[type].fill} /> : styles[type].icon}
        </div>

        <div className="ml-2 flex flex-col justify-center">
          {title ? (
            <p className={clsx("font-medium", styles[type].text)}>{title}</p>
          ) : null}

          {description ? (
            <p className="text-sm font-normal text-neutral-500">{description}</p>
          ) : null}

          {linkPosition === "bottom" && renderLink()}
        </div>
      </div>
      {linkPosition === "right" && renderLink()}
    </div>
  );
};
