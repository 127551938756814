import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";
import { distributeValuesAsPercentages } from "@/lib/math";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";

export const AppointmentDashboardTotalSchedulesSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment AppointmentDashboardTotalSchedulesSectionQueryFragment on DataItemRelatorioIndependente {
      agendamentosNovos
      reagendamentos
      mes
      agendamentosMarcados
    }
  `,
);

interface AppointmentDashboardTotalSchedulesSectionProps {
  data: FragmentType<typeof AppointmentDashboardTotalSchedulesSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalSchedulesSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardTotalSchedulesSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    AppointmentDashboardTotalSchedulesSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const { flags } = useRouteContext({ strict: false });

  const shouldShowCompletedAppointmentsSession = flags["exibe-secao-total-agendamentos"];

  if (!shouldShowCompletedAppointmentsSession) {
    return null;
  }

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showTotalSchedulesDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Total agendamentos",
      });

      void router.navigate({ search });
    } else if (searchParams.showTotalSchedulesDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const monthFilteredDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.agendamentosMarcados !== 0,
  );

  const totalNumber = currentMonthData?.agendamentosMarcados
    ? (currentMonthData.agendamentosMarcados as number)
    : 0;

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Total agendamentos
      </DashboardSectionHeader>
      {fragmentData.length === 0 || monthFilteredDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber total={totalNumber} />
          <AppointmentDashboardTotalSchedulesSectionChart
            totalCustomerSchedulesData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showTotalSchedulesDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Total agendamentos"
      >
        <p className="mb-2">
          São todos os agendamentos criados no período selecionado, divididos em:
        </p>
        <ul className="text-md list-disc list-inside gap-1 flex-col mb-2">
          <li className="list-item">
            <span className="font-bold ">Novos agendamentos: </span>
            <span>agendamentos de uma nova consulta.</span>
          </li>
          <li className="list-item">
            <span className="font-bold ">Reagendamentos: </span>
            <span>
              quando a data e/ou horário de uma consulta são alterados, ou seja, a
              consulta é remarcada.
            </span>
          </li>
        </ul>
        <p className="text-sm italic">
          Um paciente pode remarcar a consulta várias vezes. Por exemplo, se um paciente
          remarcar duas vezes, isso resultará em três agendamentos no total.
        </p>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardTotalSchedulesSectionChartProps {
  totalCustomerSchedulesData: readonly DataItemRelatorioIndependente[] | null;
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalSchedulesSectionChart = ({
  totalCustomerSchedulesData,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardTotalSchedulesSectionChartProps): JSX.Element | null => {
  if (!totalCustomerSchedulesData?.length) {
    return null;
  }

  const data = totalCustomerSchedulesData.map(
    ({ agendamentosNovos, reagendamentos, mes }) => ({
      "Novos agendamentos": agendamentosNovos as number,
      Reagendamentos: reagendamentos as number,
      mes,
    }),
  );

  const labels = data.map((entry) => {
    const values = [entry["Novos agendamentos"], entry.Reagendamentos];
    const percentages = distributeValuesAsPercentages(values);

    return {
      mes: entry.mes ?? "",
      "Novos agendamentos":
        percentages[0] >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${String(percentages[0])}%`
          : "",
      Reagendamentos:
        percentages[1] >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${String(percentages[1])}%`
          : "",
    };
  });

  return (
    <div data-testid="customer-appointment-total-schedules-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem
            dataKey="Novos agendamentos"
            fill="#72ABBC"
            disabledFill="#EDF0F2"
          />
          <ChartBarItem dataKey="Reagendamentos" fill="#003C52" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
