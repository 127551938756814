import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUserRefresh = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="none"
        stroke="#B4BEC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m1.496 11.254 1.5-1.5 1.5 1.5M22.504 12.75l-1.5 1.501-1.5-1.5"
      />
      <path
        fill="none"
        stroke="#B4BEC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.675 6.788a8.99 8.99 0 0 1 16.107 7.203M19.321 17.212A8.99 8.99 0 0 1 3.214 10.01"
      />
      <path
        fill="none"
        stroke="#B4BEC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.002 16.002a2.108 2.108 0 0 0-.529-.77v0a2.11 2.11 0 0 0-1.434-.564H9.962c-.532 0-1.045.202-1.435.564v0c-.231.215-.412.477-.529.77"
      />
      <circle
        cx={12}
        cy={10.249}
        r={2.251}
        fill="none"
        stroke="#B4BEC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  );
};
export default SvgUserRefresh;
