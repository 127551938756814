import { CircleCheck } from "@repo/icons";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface GenericSuccessDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  description?: string;
  confirmButtonText?: string;
}

export const GenericSuccessDrawer = ({
  title = "Operação realizada com sucesso!",
  confirmButtonText = "Confirmar",
  description,
  open,
  setOpen,
}: GenericSuccessDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={CircleCheck}
      variant="secondary"
    >
      <MessageDrawerTitle>{title}</MessageDrawerTitle>
      {description ? <MessageDrawerBody>{description}</MessageDrawerBody> : null}
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={() => setOpen(false)}>
          {confirmButtonText}
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
