import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header.tsx";

export const DashboardChartSection = ({
  chartTitle,
  children,
  onClick,
}: {
  chartTitle: string;
  onClick: (open: boolean) => void;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className="my-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onClick}>{chartTitle}</DashboardSectionHeader>
      {children}
    </div>
  );
};
