import { useNavigate, useRouteContext } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsTextArea } from "corpus";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql.ts";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";

export const SecretaryDetailsFaqFormFragment = graphql(/* GraphQL */ `
  fragment SecretaryDetailsFaqFormFragment on query_root {
    personalizeAppointment: usuariosProfissionaisOrientacoesSecretarias {
      Faq: informacoesDuvidasFrequentes
    }
  }
`);

const upsertAppointmentInfosMutation = graphql(/* GraphQL */ `
  mutation UpsertSecretaryDetailsFaqMutation(
    $input: usuariosProfissionaisOrientacoesSecretarias_insert_input!
  ) {
    insert_usuariosProfissionaisOrientacoesSecretarias_one(
      if_matched: {
        match_columns: codUsuario
        update_columns: informacoesDuvidasFrequentes
      }
      object: $input
    ) {
      informacoesDuvidasFrequentes
    }
  }
`);

const FaqSchema = z.object({
  Faq: z
    .string()
    .max(1000, {
      message: "As anotações não podem ter mais de 1000 caracteres",
    })
    .optional(),
});

export const SecretaryDetailsFaqForm = ({
  data,
}: {
  data: FragmentType<typeof SecretaryDetailsFaqFormFragment>;
}): React.ReactNode => {
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    upsertAppointmentInfosMutation,
  );

  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const fragmentData = useFragment(SecretaryDetailsFaqFormFragment, data);

  const form = useForm<z.infer<typeof FaqSchema>>({
    resolver: zodResolver(FaqSchema),
    values: {
      Faq: fragmentData.personalizeAppointment[0]?.Faq ?? "",
    },
  });

  const onSubmit = async (values: z.infer<typeof FaqSchema>): Promise<void> => {
    await mutateAsync({
      input: {
        codUsuario: user.codUsuario,
        informacoesDuvidasFrequentes: values.Faq,
      },
    });

    await navigate({
      to: "../",
    });
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit} className="gap-0">
        <PageTitleRoot>
          <PageTitleMainContent>Anotações</PageTitleMainContent>
          <PageTitleDescription>
            <p>
              Campo para a secretaria registrar mais informações e anotações relevantes
              sobre o seu consultório.
            </p>
            <p>Exemplos:</p>
            <p>Dr. emite nota fiscal única.</p>
            <p>
              Informar aos pacientes que o pagamento deve ser feito antes do check-in.
            </p>
          </PageTitleDescription>
        </PageTitleRoot>

        <FormField
          control={form.control}
          name="Faq"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsTextArea rows={15} {...field} />
              </FormControl>
              <p className="text-neutral-500">{`${form.watch(field.name)?.length ?? 0} / 1000`}</p>
            </FormItem>
          )}
        />

        <FormSubmitButton type="submit">Salvar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
