import { useRouter, useSearch } from "@tanstack/react-router";
import { SingleNumberChart } from "@/components/charts/single-number-chart";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardChartSection } from "@/components/charts/dashboard-chart-section";

interface TotalRescuedAppointmentsSectionProps {
  data?: {
    appointmentsRescuedSum?: number;
  };
}

export const TotalRescuedAppointmentsSection = ({
  data,
}: TotalRescuedAppointmentsSectionProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });

  const onClick = (): void => {
    searchParams.showTotalRescuedAppointmentsDrawer = open;

    void router.navigate({ search: searchParams });
  };

  if (!data?.appointmentsRescuedSum || data.appointmentsRescuedSum === 0) {
    return (
      <>
        <DashboardChartSection chartTitle="Total de Recuperações" onClick={onClick}>
          <DashboardSectionEmptyState />
        </DashboardChartSection>
        <TotalRescuedAppointmentsDrawer />
        <hr />
      </>
    );
  }

  return (
    <>
      <DashboardChartSection chartTitle="Total de Recuperações" onClick={onClick}>
        <SingleNumberChart
          totalNumber={data.appointmentsRescuedSum ?? 0}
          displayText="Total"
        />
      </DashboardChartSection>
      <TotalRescuedAppointmentsDrawer />
      <hr />
    </>
  );
};

const TotalRescuedAppointmentsDrawer = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });
  const router = useRouter();

  return (
    <DashboardInformationalDrawer
      open={Boolean(searchParams.showTotalRescuedAppointmentsDrawer)}
      onOpenChange={(open) => {
        if (!open) {
          void router.navigate({
            search: {
              showTotalRescuedAppointmentsDrawer: undefined,
            },
            replace: true,
          });
        }
      }}
      drawerTitle="Agendamentos recuperados"
    >
      <p>
        Total de agendamentos realizados após o envio de automações criadas para
        incentivar contatos e pacientes a agendarem, que se originam de três fluxos:
      </p>
      <ul className="pt-4 text-md list-disc list-inside">
        <li className="pl-4 pb-1 list-item">
          <span>Agendamentos recuperados.</span>
        </li>
        <li className="pl-4 pb-1 list-item">
          <span>Cancelamentos recuperados.</span>
        </li>
        <li className="pl-4 pb-1 list-item">
          <span>Faltas recuperadas.</span>
        </li>
      </ul>
    </DashboardInformationalDrawer>
  );
};
