import { type SVGIconProps, UpDown } from "@repo/icons";
import { cn } from "@repo/lib";
import {
  createContext,
  useContext,
  type InputHTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  forwardRef,
  type ForwardedRef,
  type FunctionComponent,
} from "react";

interface InputSelectContextProps {
  required?: boolean;
  children?: ReactNode;
}

const InputSelectContext = createContext<InputSelectContextProps>({});

const useInputSelectContext = (): InputSelectContextProps =>
  useContext(InputSelectContext);

export const InputSelect = ({
  children,
  required,
}: InputSelectContextProps): JSX.Element => {
  return (
    <InputSelectContext.Provider value={{ required }}>
      <div className="w-full">{children}</div>
    </InputSelectContext.Provider>
  );
};

interface InputSelectTitleProps {
  children?: ReactNode;
  Icon?: FunctionComponent<SVGIconProps>;
  onIconClick?: () => void;
}

export const InputSelectTitle = ({
  children,
  Icon,
  onIconClick,
}: InputSelectTitleProps): JSX.Element => {
  const { required } = useInputSelectContext();

  return (
    <div className="flex gap-2 items-center mb-1">
      <p className="font-medium">
        {children}
        {required ? "*" : null}
      </p>
      {Icon && onIconClick ? (
        <button onClick={onIconClick} tabIndex={0} type="button">
          <Icon className="fill-neutral-300" size={24} />
        </button>
      ) : null}
    </div>
  );
};

interface InputSelectContentProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onClick"> {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label?: string;
  Icon?: FunctionComponent<SVGIconProps>;
}

export const InputSelectContent = forwardRef(
  (
    { onClick, label, Icon, ...props }: InputSelectContentProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const { required } = useInputSelectContext();
    const clickableClass = props.disabled ? "cursor-auto" : "cursor-pointer";

    return (
      <button
        type="button"
        className="rounded-md border-neutral-200 relative enabled:hover:border-primary-300 focus:border-primary-400 focus:ring-primary-200 flex w-full border-2 bg-white p-4 pr-12 text-sm font-normal text-neutral-600 transition-all duration-200 placeholder:text-neutral-300 focus:outline-none focus:ring disabled:bg-neutral-50 disabled:text-neutral-300 disabled:placeholder:text-neutral-300"
        onClick={onClick}
        disabled={props.disabled}
        tabIndex={0}
      >
        <label htmlFor={props.name} className="truncate flex gap-2 items-end">
          {Icon ? <Icon className="fill-neutral-300" size={24} /> : null}
          <input
            className="hidden"
            type="hidden"
            ref={ref}
            required={required}
            {...props}
          />
          <span
            className={cn(clickableClass, "absolute right-4 top-4")}
            onClick={onClick}
            aria-hidden="true"
          >
            <UpDown className="fill-neutral-300" size={24} />
          </span>

          {label ? (
            <span className={clickableClass}>{label}</span>
          ) : (
            <span className={cn(clickableClass, "text-neutral-300")}>
              {props.placeholder}
            </span>
          )}
        </label>
      </button>
    );
  },
);

InputSelectContent.displayName = "InputSelectContent";
