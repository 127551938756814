import { Exclamation } from "@repo/icons";
import { useEffect, useState } from "react";
import { useRouteContext } from "@tanstack/react-router";
import { logError, preferencesStorageCache, zonedDate } from "@repo/lib";
import {
  MessageDrawerRoot,
  MessageDrawerTitle,
  MessageDrawerBody,
} from "@/components/message-drawer";
import { TimeType, dateDiff } from "@/lib/date";

export const PendingBillingDrawer = (): JSX.Element => {
  const [openBillingDrawer, setOpenBillingDrawer] = useState(false);
  const { user } = useRouteContext({ strict: false });
  const { dataInicioDivida } = user;

  const handlePendingBillingDrawer = async (): Promise<void> => {
    const pendingBillingKey = "lastPendingBillNotification";
    const lastNotificationStr = String(
      (await preferencesStorageCache.get(pendingBillingKey)) || "",
    );
    const now: Date = zonedDate(new Date());

    if (!lastNotificationStr) {
      await preferencesStorageCache.set(pendingBillingKey, now);
      setOpenBillingDrawer(true);
      return;
    }

    const lastNotification: Date = zonedDate(lastNotificationStr);
    const minutesBetween = dateDiff(lastNotification, now, TimeType.Minutes);

    if (minutesBetween >= 10) {
      await preferencesStorageCache.set(pendingBillingKey, now);
      setOpenBillingDrawer(true);
    }
  };

  useEffect(() => {
    if (dataInicioDivida && new Date() > dataInicioDivida) {
      handlePendingBillingDrawer().catch((e) => logError(e));
    }
  }, [dataInicioDivida]);

  return (
    <MessageDrawerRoot
      open={openBillingDrawer}
      setOpen={setOpenBillingDrawer}
      variant="danger"
      icon={Exclamation}
    >
      <MessageDrawerTitle>Cobranças em aberto</MessageDrawerTitle>
      <MessageDrawerBody>
        <div className="text-balance pb-14">
          <p>Você possui cobranças em aberto.</p>
          <p>
            Caso o atraso seja maior que <span className="font-semibold">15 dias</span>,{" "}
            sua <span className="font-semibold">conta poderá ser bloqueada</span>.
          </p>
          <p>
            Regularize a sua situação financeira no{" "}
            <span className="font-semibold">admin.livance.com.br</span> ou via{" "}
            <span className="font-semibold">WhatsApp</span> da sua unidade preferencial.
          </p>
        </div>
      </MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
