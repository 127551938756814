import { CheckCircle } from "@repo/icons";
import { useAtom } from "jotai";
import { Share as CapacitorShare } from "@capacitor/share";
import { useSearch } from "@tanstack/react-router";
import { CpsToast } from "corpus";
import { useState } from "react";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { createPaymentFormAtom } from "@/lib/atoms/create-payment-atom";
import { generateOnlinePaymentLink } from "@/lib/online-payment";
import { trackEvent } from "@/lib/tracking";

interface CreatePaymentConfirmSuccessDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CreatePaymentConfirmSuccessDrawer = ({
  open,
  setOpen,
}: CreatePaymentConfirmSuccessDrawerProps): JSX.Element => {
  const { guid, origin } = useSearch({ from: "/settings/payments/create/confirmation" });
  const [atomValues] = useAtom(createPaymentFormAtom);
  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const shareLink = async (): Promise<void> => {
    const url = generateOnlinePaymentLink(guid ?? "");

    trackEvent("Pagamento Online Link Compartilhado", {
      origem: origin,
    });

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(url);
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      url,
    });
  };

  const getShareLinkMessage = (): string => {
    if (atomValues.sendPaymentAutomatically) {
      return 'O link de pagamento será enviado automaticamente ao paciente. Se preferir, você pode compartilhar o link pelo botão abaixo ou acessá-lo na seção "Pagamentos" a qualquer momento.';
    }

    return 'Utilize o botão abaixo para compartilhar o link de pagamento. Você também pode acessá-lo na seção "Pagamentos" a qualquer momento.';
  };

  return (
    <>
      <MessageDrawerRoot
        open={open}
        setOpen={setOpen}
        icon={CheckCircle}
        variant="secondary"
      >
        <MessageDrawerTitle>
          Pagamento criado no valor de R$ {atomValues.value}
        </MessageDrawerTitle>
        <MessageDrawerBody>{getShareLinkMessage()}</MessageDrawerBody>
        <MessageDrawerActions>
          <MessageDrawerActionButton
            onClick={() => {
              void shareLink();
            }}
          >
            Compartilhar
          </MessageDrawerActionButton>
        </MessageDrawerActions>
      </MessageDrawerRoot>
      <CpsToast
        title="Link copiado para a área de transferência"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={10}
      />
    </>
  );
};
