"use client";

import {
  Children,
  cloneElement,
  type Dispatch,
  type MouseEvent as ReactMouseEvent,
  type ReactElement,
  type SetStateAction,
  useEffect,
  isValidElement,
} from "react";
import { cn } from "@repo/cn";
import { type ICpsTab } from "../CpsTab";

interface CpsTabListProps {
  children: ReactElement<ICpsTab> | ReactElement<ICpsTab>[];
  selectedTabIndex: number;
  setSelected: Dispatch<SetStateAction<number>>;
  className?: string;
}

/**
 * Renders a list of tabs.
 *
 * @example
 * ```tsx
 * <CpsTabList selectedTabIndex={1} setSelected={(index) => setPage(index)}>
 *   <CpsTab Icon={User1}>Minha conta</CpsTab>
 *   <CpsTab Icon={Download}>Relatórios</CpsTab>
 *   <CpsTab Icon={Cash}>Recebimentos</CpsTab>
 *   <CpsTab Icon={Info}>Informações</CpsTab>
 * </CpsTabList>
 * ```
 * @param children - The list of tabs.
 * @param selectedTabIndex - The index of the selected tab.
 * @param setSelected - A function that sets the selected tab.
 * @param className - tailwind classes to be applied to the component.
 *
 * @returns The `CpsTabList` component.
 */
export const CpsTabList = ({
  children,
  selectedTabIndex,
  setSelected,
  className,
}: CpsTabListProps): JSX.Element => {
  const arrayChildren = Children.toArray(children).filter(
    (child): child is ReactElement<ICpsTab> => isValidElement(child),
  );

  useEffect(() => {
    const cpsTabEl = document.getElementById(selectedTabIndex.toString());

    if (!cpsTabEl) {
      return;
    }

    cpsTabEl.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [selectedTabIndex]);

  const el = Children.map(arrayChildren, (child: ReactElement<ICpsTab>, index) => {
    return cloneElement(child, {
      ...child.props,
      id: index.toString(),
      selected: selectedTabIndex === index,
      onClick: (event: ReactMouseEvent<HTMLButtonElement>) => {
        child.props.onClick?.(event);
        setSelected(index);
      },
    });
  });

  return (
    <div className={cn("cps-tab-list-container flex w-full overflow-x-auto", className)}>
      {el}
    </div>
  );
};
