import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { PendingComponent } from "@/components/pending-component";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import { AppointmentInfosForm } from "@/components/secretary-details/appointment-infos-form.tsx";
import { useSuspenseGraphQL } from "@/hooks/use-graphql.ts";

export const GetSecretaryAppointmentInfosQuery = graphql(/* GraphQL */ `
  query GetSecretaryAppointmentInfosQuery {
    ...AppointmentInfosFormFragment
  }
`);

const AppointmentInfosPage = (): React.ReactNode => {
  const { data } = useSuspenseGraphQL(GetSecretaryAppointmentInfosQuery, {});
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="" align="center" />
      </HeaderRoot>
      <Page>
        <AppointmentInfosForm data={data} />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/assist/secretary-details/appointment-infos",
)({
  component: AppointmentInfosPage,
  pendingComponent: () => <PendingComponent title="Informações de atendimento" />,
});
