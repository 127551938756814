import { type ParsedLocation, redirect } from "@tanstack/react-router";
import { type UserContext } from "@repo/lib";

export const validateUserAccess = ({
  location,
  user,
  codFuncionalidade,
}: {
  location: ParsedLocation;
  user: UserContext;
  codFuncionalidade?: number;
}): void => {
  const userHasAccess = hasUserAccess(user, codFuncionalidade);

  if (location.pathname.includes("assist/welcome")) {
    if (userHasAccess) {
      redirectToSettings();
    }
    return;
  }

  if (location.pathname.includes("assist/customer-contact-overview/stories-feed")) {
    return;
  }

  if (!userHasAccess) {
    redirectToWelcome();
  }
};

const hasUserAccess = (user: UserContext, codFuncionalidade?: number): boolean => {
  return user.funcionalidadesContratadas.some((x) =>
    codFuncionalidade ? x.codFuncionalidade === codFuncionalidade && x.ativo : x.ativo,
  );
};

const redirectToSettings = (): void => {
  redirect({ to: "/settings/assist/", throw: true });
};

const redirectToWelcome = (): void => {
  redirect({ to: "/settings/assist/welcome", throw: true });
};
