import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Link } from "@tanstack/react-router";
import { App, Click, Link1, TrendingUp } from "@repo/icons";
import { Button } from "@/components/button";
import {
  EmptyStateBody,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateInfoTable,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { ListRoot } from "@/components/list";
import { UserTrackableLinksListItem } from "@/components/user-trackable-links/user-trackable-links-list-item";

/**
 * Este fragmento GraphQL é usado para buscar uma lista de links rastreáveis de usuários.
 *
 * Ele ordena os resultados pelo campo 'createdAt' em ordem descendente.
 */
export const UserTrackableLinksQueryFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinksQueryFragment on query_root {
    usuariosLinksRastreamentos(order_by: { dataCadastro: desc }) {
      codUsuarioLinkRastreamento
      ...UserTrackableLinkListItemQueryFragment
    }
  }
`);

export const UserTrackableLinksList = (props: {
  userTrackableLinkList: FragmentType<typeof UserTrackableLinksQueryFragment>;
}): JSX.Element => {
  const { usuariosLinksRastreamentos } = useFragment(
    UserTrackableLinksQueryFragment,
    props.userTrackableLinkList,
  );

  const infoElements = [
    {
      icon: App,
      info: "Crie um link para cada fonte de indicação ou para suas divulgações de marketing",
    },
    {
      icon: Click,
      info: "Direcione esse link para o seu site ou para agendamento online",
    },
    {
      icon: TrendingUp,
      info: "Acompanhe os resultados de cada link e evolua seu consultório",
    },
  ];

  return (
    <div className="justify-center items-center">
      {usuariosLinksRastreamentos.length > 0 ? (
        <>
          <ListRoot>
            {usuariosLinksRastreamentos.map((userTrackableLink) => (
              <UserTrackableLinksListItem
                userTrackableLinkItem={userTrackableLink}
                key={userTrackableLink.codUsuarioLinkRastreamento}
              />
            ))}
          </ListRoot>
          <div className="sticky bottom-0 pb-4">
            <Link to="/settings/assist/user-trackable-links/create">
              <Button fullWidth>Criar link com rastreamento</Button>
            </Link>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-between h-full items-center bg-white mt-8">
          <EmptyStateRoot>
            <EmptyStateIcon icon={Link1} />
            <EmptyStateTitle>Links com rastreamento</EmptyStateTitle>
            <EmptyStateDescription>
              Permitem acompanhar a conversão em agendamentos de diversos canais.
            </EmptyStateDescription>
            <EmptyStateBody>
              <EmptyStateInfoTable elements={infoElements} />
            </EmptyStateBody>
            <Link to="/settings/assist/user-trackable-links/create" className="w-full">
              <EmptyStateButton fullWidth>Criar link com rastreamento</EmptyStateButton>
            </Link>
          </EmptyStateRoot>
        </div>
      )}
    </div>
  );
};
