import { useRouter } from "@tanstack/react-router";
import { Button } from "@/components/button";

export const PatientPaymentAbout = (): JSX.Element => {
  const router = useRouter();

  const handleClickBackButton = (): void => {
    router.history.back();
  };

  return (
    <div className="flex-col flex gap-4">
      <h1 className="font-semibold text-lg text-neutral-600">Pagamentos</h1>
      <p>
        Nesta seção, você pode consultar todas as informações relacionadas aos pagamentos
        realizados pelos pacientes. É possível visualizar o valor pago, a forma de
        pagamento utilizada, detalhes sobre o agendamento, o nome do paciente e o status
        atual de cada pagamento.
      </p>

      <section className="flex-col flex gap-4">
        <h2 className="text-lg font-semibold text-neutral-600">Entenda os status:</h2>

        <p>
          <span className="font-semibold text-neutral-600">Em aberto:</span> O pagamento
          ainda não foi realizado.
        </p>

        <p>
          <span className="font-semibold text-neutral-600">Pago:</span> O pagamento foi
          realizado com sucesso e está aprovado.
        </p>

        <p>
          <span className="font-semibold text-neutral-600">Estornado:</span> O valor do
          pagamento foi devolvido ao paciente após o pedido do estorno. O prazo para
          estorno depende do tipo de pagamento que foi realizado e pode variar de acordo
          com o emissor do cartão.
        </p>
      </section>

      <Button onClick={handleClickBackButton}>Voltar</Button>
    </div>
  );
};
