import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type AppointmentDashboardLatePatientsSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { ChartRoot, ChartBarContainer, ChartBarItem } from "@/components/charts";
import { trackEvent } from "@/lib/tracking";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";

export const AppointmentDashboardLatePatientsSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment AppointmentDashboardLatePatientsSectionQueryFragment on DataItemRelatorioIndependente {
      mes
      agendamentosPacienteAtrasado
    }
  `,
);

interface AppointmentDashboardLatePatientsSectionProps {
  data: FragmentType<typeof AppointmentDashboardLatePatientsSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardLatePatientsSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardLatePatientsSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    AppointmentDashboardLatePatientsSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const { flags } = useRouteContext({ strict: false });

  const shouldShowAppointmentsLatePatients = flags["exibe-secao-pacientes-atrasados"];

  if (!shouldShowAppointmentsLatePatients) {
    return null;
  }

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showLatePatientsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Pacientes atrasados",
      });

      void router.navigate({ search });
    } else if (searchParams.showLatePatientsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.agendamentosPacienteAtrasado !== 0,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Pacientes atrasados
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={Number(currentMonthData?.agendamentosPacienteAtrasado ?? 0)}
          />
          <AppointmentDashboardLatePatientsSectionChart
            chartData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showLatePatientsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Pacientes atrasados"
      >
        <p>
          Este gráfico mostra as consultas com atraso de pacientes, com check-in realizado
          após o horário de início da consulta.
        </p>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardLatePatientsSectionChartProps {
  chartData: readonly AppointmentDashboardLatePatientsSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardLatePatientsSectionChart = ({
  chartData,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardLatePatientsSectionChartProps): JSX.Element => {
  const data = chartData.map(({ agendamentosPacienteAtrasado, mes }) => ({
    "Pacientes atrasados": agendamentosPacienteAtrasado as number,
    mes,
  }));

  const labels = data.map((entry) => {
    const latePatients = entry["Pacientes atrasados"];
    return {
      mes: entry.mes ?? "",
      "Pacientes atrasados": latePatients.toString(),
    };
  }) as Record<string, string>[];

  return (
    <div data-testid="appointments-late-patients-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem
            dataKey="Pacientes atrasados"
            fill="#003C52"
            disabledFill="#EDF0F2"
          />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
