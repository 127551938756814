import { Link } from "@tanstack/react-router";
import { CpsButton } from "corpus";
import { trackEvent } from "@/lib/tracking";

interface CheckOtherDashboardButtonProps {
  buttonText: string;
  targetRoute: string;
  searchParams?: Record<string, string>;
}

export const CheckOtherDashboardButton = ({
  buttonText,
  targetRoute,
  searchParams,
}: CheckOtherDashboardButtonProps): JSX.Element => {
  const onClick = (): void => {
    trackEvent("Ver outro relatório selecionado", {
      "Relatório selecionado": buttonText.includes("consultas")
        ? "Consultas"
        : "Atendimento",
    });
  };

  return (
    <Link className="flex justify-center" to={targetRoute} search={searchParams}>
      <CpsButton onClick={onClick}>{buttonText}</CpsButton>
    </Link>
  );
};
