import { createFileRoute, useRouter } from "@tanstack/react-router";
import { Graph, Left, Click, User1, IncreasePeople, Link } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { Browser } from "@capacitor/browser";
import { UserQueryDocument } from "@repo/graphql-types/graphql";
import {
  EmptyStateBody,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateInfoTable,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import { HeaderLinkButton, HeaderRoot, HeaderTitle } from "@/components/header";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

const AcceptLivanceAssistMutation = graphql(/* GraphQL */ `
  mutation AcceptLivanceAssistMutation {
    contratarPacoteServicos(input: { codPacoteServico: 1 }) {
      contratarPacoteServicosOutput {
        codPacoteServico
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
      }
    }
  }
`);

export const WelcomePage = (): JSX.Element => {
  const router = useRouter();
  const { mutate, isPending } = useGraphQLMutationWithErrorHandler(
    AcceptLivanceAssistMutation,
  );

  const invalidateUserQuery = useInvalidateQuery(UserQueryDocument);

  const submitAcceptedAssist = (): void => {
    const onSuccess = (): void => {
      invalidateUserQuery();

      void router.navigate({
        to: "/settings/assist/customer-contact-overview/stories-feed/",
      });
    };

    mutate({}, { onSuccess });
  };

  const handleLinkClick = async (): Promise<void> => {
    await Browser.open({
      url: "https://consultorios.livance.com.br/lp/livanceassistpro",
      windowName: "_self",
    });
  };

  const infoElements = [
    {
      icon: Graph,
      info: "Relatórios detalhados para decisões inteligentes",
    },
    {
      icon: Click,
      info: "Novas ferramentas para converter mais pacientes.",
    },
    {
      icon: User1,
      info: "Maior suporte do nosso time de secretárias.",
    },
  ];

  return (
    <>
      <HeaderRoot>
        <HeaderLinkButton icon={Left} align="start" to="/settings" />
        <HeaderTitle title="Livance Assist Pro" align="center" />
      </HeaderRoot>
      <div className="pt-8">
        <EmptyStateRoot>
          <EmptyStateIcon icon={IncreasePeople} />
          <EmptyStateTitle>Livance Assist Pro</EmptyStateTitle>
          <EmptyStateDescription>
            Conheça o novo produto da Livance que é a evolução do serviço de secretárias.
          </EmptyStateDescription>
          <EmptyStateBody>
            <EmptyStateInfoTable elements={infoElements} />
          </EmptyStateBody>
          <li className="text-left text-sm flex flex-row gap-1 items-center font-medium">
            <button
              type="button"
              onClick={() => {
                void handleLinkClick();
              }}
              className="text-[#0080a5] underline"
            >
              Saiba mais sobre o Livance Assist Pro
            </button>
            <Link size={16} className="fill-[#0080a5]" />
          </li>
          <EmptyStateButton loading={isPending} onClick={submitAcceptedAssist} fullWidth>
            Experimentar
          </EmptyStateButton>
        </EmptyStateRoot>
      </div>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/welcome/")({
  component: WelcomePage,
});
