import { useEffect } from "react";
import { iosOrAndroid } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import { CustomerIO } from "@repo/customerio-plugin";

export const usePushNotificationPrompt = (): void => {
  const { user } = useRouteContext({ strict: false });
  const { codUsuario, email = "" } = user;

  useEffect(() => {
    if (codUsuario === 0) {
      return;
    }

    if (iosOrAndroid && codUsuario) {
      CustomerIO.requestPushNotificationsPermission();

      CustomerIO.identify({ identifier: codUsuario.toString(), email });
    }
  }, [codUsuario, email]);
};
