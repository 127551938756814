"use client";

import type { SVGIconProps } from "@repo/icons";
import { X } from "@repo/icons";
import { clsx } from "clsx";
import type { FunctionComponent, MouseEventHandler } from "react";
import React, { useEffect, useState } from "react";
import { CpsButton } from "../CpsButton";
import { CpsButtonText } from "../CpsButtonText";
import type { CpsFeedbackBubbleFill } from "../CpsFeedbackBubble";
import CpsFeedbackBubble from "../CpsFeedbackBubble";
import CpsModalOverlay from "../CpsModalOverlay";

type CpsTextAlign = "left" | "center" | "right";

interface ICpsDialog {
  title: string;
  show: boolean;
  setShow: any;
  primaryBtnLabel?: string;
  Icon?: FunctionComponent<SVGIconProps>;
  iconFill?: CpsFeedbackBubbleFill;
  btnRounded?: boolean;
  description?: string;
  textAlign?: CpsTextAlign;
  btnOnClick?: MouseEventHandler<HTMLButtonElement>;
  secondaryBtnLabel?: string;
  secondaryBtnOnClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Creates a dialog.
 *
 * @example
 * <CpsDialog title="My Dialog" isOpen={this.state.isOpen} onClose={this.handleClose}>
 *     <p>This is a dialog box.</p>
 * </CpsDialog>
 * ```
 *
 * @param title - The title of the dialog.
 * @param show - A boolean that determines whether the dialog is open.
 * @param setShow - A function that is called when the dialog is closed.
 * @param primaryBtnLabel - The label of the primary button.
 * @param secondaryBtnLabel - The label of the secondary button.
 * @param Icon - The icon of the dialog.
 * @param iconFill - The fill of the icon.
 * @param btnRounded - Whether the primary button should be rounded.
 * @param description - The description of the dialog.
 * @param textAlign - The alignment of the text.
 * @param btnOnClick - The function to be called when the primary button is clicked.
 * @param secondaryBtnOnClick - The function to be called when the secondary button is clicked.
 */
export const CpsDialog: React.FC<ICpsDialog> = ({
  title,
  show,
  setShow,
  primaryBtnLabel,
  secondaryBtnLabel,
  Icon,
  iconFill = "primary",
  btnRounded,
  description,
  textAlign = "center",
  btnOnClick,
  secondaryBtnOnClick,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);

    setTimeout(
      () => {
        setShow();
      },
      window.screen.width < 1280 ? 400 : 0,
    );
  };

  useEffect(() => {
    const overflowClass = "overflow-y-hidden";
    show
      ? document.documentElement.classList.add(overflowClass)
      : document.documentElement.classList.remove(overflowClass);
  }, [show]);

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const textAlignClassName = `text-${textAlign}`;

  return (
    <CpsModalOverlay show={show} onClick={handleCloseDialog}>
      <div
        id="dialog"
        tabIndex={-1}
        className={clsx(
          "fixed inset-x-0 z-50 mx-auto my-0 h-fit max-h-[95%] min-h-[200px] w-full max-w-[632px] items-center rounded-t-lg bg-white p-8 shadow transition-all duration-500 xl:left-[50%] xl:top-[50%] xl:m-0 xl:flex xl:translate-x-[-50%] xl:translate-y-[-50%] xl:flex-col xl:overflow-auto xl:rounded-b-lg",
          showDialog ? "bottom-0" : "bottom-[-100%] xl:bottom-0",
        )}
      >
        <div className="relative">
          <div className="-top-3 absolute right-0">
            <button
              type="button"
              className="ml-auto mt-1 inline-flex items-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => handleCloseDialog()}
              aria-label="Fechar modal"
            >
              <X size={24} />
            </button>
          </div>
          <div>
            {Icon ? <>
                <div className="mb-3 hidden w-full justify-center xl:flex">
                  <CpsFeedbackBubble Icon={Icon} fill={iconFill} size="large" />
                </div>
                <div className="mb-3 flex w-full justify-center xl:hidden">
                  <CpsFeedbackBubble Icon={Icon} fill={iconFill} size="medium" />
                </div>
              </> : null}

            <div className="flex flex-col justify-center">
              <p
                className={`text-xl font-semibold leading-7 text-neutral-600 xl:text-2xl xl:leading-8 ${textAlignClassName}`}
              >
                {title}
              </p>
              {description ? <p
                  className={`mt-3 text-md font-normal leading-6 text-neutral-400 whitespace-pre-line ${textAlignClassName}`}
                >
                  {description}
                </p> : null}
            </div>
            {(primaryBtnLabel || secondaryBtnLabel) ? <div className="mt-8 flex w-full flex-col justify-center">
                {primaryBtnLabel ? <div className="flex w-full items-center justify-center">
                    <CpsButton rounded={btnRounded} onClick={btnOnClick} fullWidth>
                      {primaryBtnLabel}
                    </CpsButton>
                  </div> : null}

                {secondaryBtnLabel ? <div className="flex w-full items-center justify-center">
                    <CpsButtonText color="neutral" onClick={secondaryBtnOnClick}>
                      {secondaryBtnLabel}
                    </CpsButtonText>
                  </div> : null}
              </div> : null}
          </div>
        </div>
      </div>
    </CpsModalOverlay>
  );
};

export default CpsDialog;
