import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type NuntiusGetCustomerContactManyPeriodSummaryOccurrenceData } from "@repo/graphql-types/graphql";
import { useMemo, useState } from "react";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { noSchedulingReasonMap } from "@/enums/customer-contact/no-scheduling-reason";
import { trackEvent } from "@/lib/tracking";
import {
  NumberedListRoot,
  NumberedListItem,
  NumberedListLabel,
  NumberedListValue,
} from "@/components/numbered-list";

export const CustomerContactDashboardReasonsForNotSchedulingSectionQueryFragment =
  graphql(/* GraphQL */ `
    fragment CustomerContactDashboardReasonsForNotSchedulingSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      period
      mostFrequentNotScheduledAppointmentReasons {
        key
        total
        percent
      }
    }
  `);

interface CustomerContactDashboardReasonsForNotSchedulingSectionProps {
  data: FragmentType<
    typeof CustomerContactDashboardReasonsForNotSchedulingSectionQueryFragment
  >[];
  selectedMonth: string;
}

export const CustomerContactDashboardReasonsForNotSchedulingSection = ({
  data,
  selectedMonth,
}: CustomerContactDashboardReasonsForNotSchedulingSectionProps): JSX.Element => {
  const fragmentData = useFragment(
    CustomerContactDashboardReasonsForNotSchedulingSectionQueryFragment,
    data,
  );

  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });
  const router = useRouter();

  const onOpenChange = (openDrawer: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (openDrawer) {
      search.showReasonForNotSchedullingDrawer = openDrawer;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Motivos de não agendamento",
      });

      void router.navigate({ search });
    } else if (searchParams.showReasonForNotSchedullingDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  return (
    <div data-testid="reasons-for-not-scheduling" className="mt-5">
      <div className="my-8 flex flex-col gap-2">
        <DashboardSectionHeader onClick={onOpenChange}>
          Motivos de não agendamento
        </DashboardSectionHeader>
        {fragmentData.length === 0 ||
        !currentMonthData?.mostFrequentNotScheduledAppointmentReasons.length ||
        currentMonthData.mostFrequentNotScheduledAppointmentReasons.every(
          (x) => x.total === 0,
        ) ? (
          <DashboardSectionEmptyState />
        ) : (
          <CustomerContactDashboardReasonsForNotSchedulingSectionMonthContent
            data={currentMonthData.mostFrequentNotScheduledAppointmentReasons}
          />
        )}
        <DashboardInformationalDrawer
          open={Boolean(searchParams.showReasonForNotSchedullingDrawer)}
          onOpenChange={onOpenChange}
          drawerTitle="Motivos de não agendamento"
        >
          <p className="text-md">
            Os motivos de não agendamento são registrados pelo nosso time de secretárias
            com base na interação direta com o paciente, identificando as razões que
            impediram a finalização do agendamento.
          </p>
        </DashboardInformationalDrawer>
      </div>
    </div>
  );
};

interface CustomerContactDashboardReasonsForNotSchedulingSectionMonthContentProps {
  data: NuntiusGetCustomerContactManyPeriodSummaryOccurrenceData[];
}

const CustomerContactDashboardReasonsForNotSchedulingSectionMonthContent = ({
  data,
}: CustomerContactDashboardReasonsForNotSchedulingSectionMonthContentProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleData = useMemo(
    () => (isExpanded ? data : data.slice(0, 4)),
    [data, isExpanded],
  );

  return (
    <div className="mt-6 gap-4 flex flex-col">
      <NumberedListRoot>
        {visibleData.map((x, index) => (
          <NumberedListItem key={x.key}>
            <NumberedListLabel index={index}>
              {noSchedulingReasonMap[x.key]}
            </NumberedListLabel>
            <NumberedListValue value={x.total} percentage={x.percent} />
          </NumberedListItem>
        ))}
      </NumberedListRoot>

      {data.length > 4 && (
        <button
          type="button"
          aria-label={isExpanded ? "Mostrar menos motivos" : "Mostrar mais motivos"}
          aria-expanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-secondary-400 font-medium text-sm text-center underline"
        >
          {isExpanded ? "Ver menos" : "Ver mais"}
        </button>
      )}
    </div>
  );
};
