import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { useSuspenseGraphQL } from "@/hooks/use-graphql.ts";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import { PendingComponent } from "@/components/pending-component.tsx";
import { SecretaryDetailsFaqForm } from "@/components/secretary-details/faq-form.tsx";

export const GetSecretaryDetailsFaqQuery = graphql(/* GraphQL */ `
  query GetSecretaryDetailsFaqQuery {
    ...SecretaryDetailsFaqFormFragment
  }
`);

const ProfessionalInfosPage = (): React.ReactNode => {
  const { data } = useSuspenseGraphQL(GetSecretaryDetailsFaqQuery, {});
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="" align="center" />
      </HeaderRoot>
      <Page>
        <SecretaryDetailsFaqForm data={data} />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/secretary-details/faq")({
  component: ProfessionalInfosPage,
  pendingComponent: () => <PendingComponent title="" />,
});
