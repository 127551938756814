"use client";

import type { ComponentPropsWithoutRef, MouseEventHandler, ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "@repo/cn";
import { cva } from "cva";

export interface CpsBadgeProps extends ComponentPropsWithoutRef<"span"> {
  size?: "default" | "large";
  color?: "neutral" | "danger" | "warning" | "success" | "helper" | "primary";
  onClick?: MouseEventHandler<HTMLSpanElement>;
  gap?: boolean;
  children: ReactNode;
}

const badgeClasses = cva({
  base: "py-1 px-2 flex select-none items-center justify-center text-center font-medium rounded-70 text-neutral-600 whitespace-nowrap w-fit",
  variants: {
    size: {
      default: "text-xs h-[24px]",
      large: "text-sm h-[26px]",
    },
    color: {
      neutral: "bg-neutral-100",
      danger: "bg-danger-100",
      warning: "bg-warning-100",
      success: "bg-success-100",
      helper: "bg-helper-100",
      primary: "bg-primary-100",
    },
    gap: {
      true: "gap-1",
    },
  },
  defaultVariants: {
    size: "default",
    color: "neutral",
  },
});

/**
 * Renders a badge.
 *
 * @example
 * ```tsx
 * <CpsBadge color="danger">
 *     Lorem ipsum dolor sit amet
 * </CpsBadge>
 * ```
 * @param size - The size of the badge.
 * @param color - The color of the badge.
 * @param children - The text of the badge.
 * @param onClick - A function that is called when the badge is clicked.
 *
 * @returns The `CpsBadge` component.
 */
export const CpsBadge = forwardRef<HTMLSpanElement, CpsBadgeProps>(
  ({ size = "default", color = "neutral", children, onClick, className, gap }, ref) => {
    return (
      <span
        className={cn(
          badgeClasses({
            size,
            color,
            gap,
          }),
          className,
        )}
        ref={ref}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role={onClick ? "button" : undefined}
        tabIndex={onClick ? 0 : undefined}
      >
        {children}
      </span>
    );
  },
);

CpsBadge.displayName = "CpsBadge";

const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (event) => {
  if (event.key === "Enter" || event.key === " ") {
    const syntheticEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    event.currentTarget.dispatchEvent(syntheticEvent);
  }
};
