/* eslint-disable import/no-named-as-default-member -- react boot */
/* eslint-disable @typescript-eslint/no-non-null-assertion -- react boot */
// Esse é um comentário importante. Leia
import ReactDOM from "react-dom/client";
import {
  createRouter,
  type ErrorComponentProps,
  type Router,
  RouterProvider,
} from "@tanstack/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ENVIRONMENT, initSentry } from "@repo/lib";
import { lazy, StrictMode, Suspense, useMemo } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { ErrorPage, NotFoundPage } from "@/components/error-page";
import { routeTree } from "@/route-tree.gen";
import { initialRootContext } from "@/routes/-root-context.tsx";
import { initAutoUpdate } from "@/lib/auto-updater";
import { setStatusBarStyle } from "@/lib/status-bar";
import { trackPageView } from "@/lib/track-page-view";
import {
  LaunchDarklyClient,
  DefaultFeatureFlagsValue,
  withDefaultLDProvider,
  type FeatureFlags,
} from "@/lib/launchdarkly.ts";
import { initDeepLink } from "@/lib/deep-link.ts";

initAutoUpdate();

const router = createRouter({
  routeTree,
  defaultErrorComponent: (props: ErrorComponentProps) => (
    <ErrorPage error={props.error} />
  ),
  context: initialRootContext,
  defaultNotFoundComponent: () => <NotFoundPage />,
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

router.subscribe("onLoad", ({ fromLocation, toLocation }) => {
  if (fromLocation.pathname !== toLocation.pathname) {
    window.userpilot?.reload();
    trackPageView(toLocation.pathname, { from: fromLocation.pathname });
  }
});

void initSentry();
void initDeepLink(router);
void setStatusBarStyle();

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const DevMode = (props: { router: Router }): JSX.Element => {
  const TanStackRouterDevtools =
    ENVIRONMENT === "production"
      ? () => null // Render nothing in production
      : lazy(() =>
          // Lazy load in development
          import("@tanstack/router-devtools").then((res) => ({
            default: res.TanStackRouterDevtools,
          })),
        );

  return (
    <Suspense>
      <TanStackRouterDevtools position="top-right" router={props.router} />
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </Suspense>
  );
};

const App = (): JSX.Element | null => {
  const ldClient = useLDClient();
  const flags = useFlags<FeatureFlags>();

  const localLaunchDarklyClient = useMemo(
    () => (ldClient ? new LaunchDarklyClient(ldClient) : undefined),
    [ldClient],
  );

  // Espera até que o ldClient esteja disponível para evitar flick
  if (!ldClient || !localLaunchDarklyClient) {
    return null;
  }

  return (
    <QueryClientProvider client={initialRootContext.queryClient}>
      <RouterProvider
        router={router}
        context={{
          ldClient: localLaunchDarklyClient,
          flags: Object.values(flags).length ? flags : DefaultFeatureFlagsValue,
        }}
      />
      <DevMode router={router} />
    </QueryClientProvider>
  );
};

const AppWithLaunchDarkly = withDefaultLDProvider(App);

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <AppWithLaunchDarkly />
    </StrictMode>,
  );
}

// Cache buster
