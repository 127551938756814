import { useRouter, useSearch } from "@tanstack/react-router";
import { SingleNumberChart } from "@/components/charts/single-number-chart";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { trackEvent } from "@/lib/tracking";
import { ChartLegendItem } from "@/components/charts";
import { DashboardChartSection } from "@/components/charts/dashboard-chart-section.tsx";

interface RescuedCancelledAppointmentsSectionProps {
  data?: {
    month: string;
    rescuedAppointmentType: string;
    appointmentsRescuedSum?: number;
    sentMessagesSum?: number;
  };
}

export const RescuedCancelledAppointmentsSection = ({
  data,
}: RescuedCancelledAppointmentsSectionProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });

  const onClick = (): void => {
    searchParams.showRescuedCancelledAppointmentsDrawer = true;

    trackEvent("Definição Gráfico Aberta", {
      grafico: "Cancelamentos recuperados",
    });

    void router.navigate({ search: searchParams });
  };

  const displayText =
    data?.appointmentsRescuedSum && data.appointmentsRescuedSum > 1
      ? "Cancelamentos recuperados"
      : "Cancelamento recuperado";

  const legendText =
    data?.sentMessagesSum && data.sentMessagesSum > 1
      ? "Mensagens enviadas"
      : "Mensagem enviada";

  if (!data) {
    return (
      <DashboardChartSection chartTitle="Cancelamentos recuperados" onClick={onClick}>
        <DashboardSectionEmptyState />
        <RescuedCancelledAppointmentsDrawer />
      </DashboardChartSection>
    );
  }

  return (
    <DashboardChartSection chartTitle="Cancelamentos recuperados" onClick={onClick}>
      <SingleNumberChart
        totalNumber={data.appointmentsRescuedSum ?? 0}
        displayText={displayText}
      />
      <ChartLegendItem
        value={data.sentMessagesSum?.toString() ?? "0"}
        displayText={legendText}
        index={1}
      />
      <RescuedCancelledAppointmentsDrawer />
    </DashboardChartSection>
  );
};

const RescuedCancelledAppointmentsDrawer = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });
  const router = useRouter();

  return (
    <DashboardInformationalDrawer
      open={Boolean(searchParams.showRescuedCancelledAppointmentsDrawer)}
      drawerTitle="Cancelamentos recuperados"
      onOpenChange={(open) => {
        if (!open) {
          void router.navigate({
            search: {
              showRescuedCancelledAppointmentsDrawer: false,
            },
            replace: true,
          });
        }
      }}
    >
      Para pacientes que cancelaram e não remarcaram, enviamos uma mensagem convidando-os
      a realizar um novo agendamento. Este gráfico mostra quantos realizaram o agendamento
      após receberem nossa mensagem.
    </DashboardInformationalDrawer>
  );
};
