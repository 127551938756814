import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import { ChartRoot, ChartBarContainer, ChartBarItem } from "@/components/charts";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";

export const AppointmentDashboardTotalCancelledSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment AppointmentDashboardTotalCancelledSectionQueryFragment on DataItemRelatorioIndependente {
      cancelamentosDefinitivos
      mes
    }
  `,
);

interface AppointmentDashboardTotalCancelledSectionProps {
  data: FragmentType<typeof AppointmentDashboardTotalCancelledSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalCancelledSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardTotalCancelledSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    AppointmentDashboardTotalCancelledSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const { flags } = useRouteContext({ strict: false });

  const shouldShowCompletedAppointmentsSession =
    flags["exibe-secao-agendamentos-cancelados"];

  if (!shouldShowCompletedAppointmentsSession) {
    return null;
  }

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showTotalCancelledDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Agendamentos Cancelados",
      });

      void router.navigate({ search });
    } else if (searchParams.showTotalCancelledDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const monthFilteredDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.cancelamentosDefinitivos !== 0,
  );

  const totalNumber = currentMonthData?.cancelamentosDefinitivos
    ? (currentMonthData.cancelamentosDefinitivos as number)
    : 0;

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Agendamentos Cancelados
      </DashboardSectionHeader>
      {fragmentData.length === 0 || monthFilteredDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber total={totalNumber} />
          <AppointmentDashboardTotalCancelledSectionChart
            totalCancelledData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showTotalCancelledDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Agendamentos Cancelados"
      >
        <p className="mb-2">
          Este gráfico mostra os agendamentos cancelados no período selecionado,
          considerando a data do cancelamento e não a data da consulta.
        </p>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardTotalCancelledSectionChartProps {
  totalCancelledData: readonly DataItemRelatorioIndependente[] | null;
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalCancelledSectionChart = ({
  totalCancelledData,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardTotalCancelledSectionChartProps): JSX.Element | null => {
  if (!totalCancelledData?.length) {
    return null;
  }

  const data = totalCancelledData.map(({ cancelamentosDefinitivos, mes }) => ({
    Cancelamentos: cancelamentosDefinitivos as number,
    mes: mes ?? "",
  }));

  return (
    <div data-testid="customer-appointment-total-cancelled-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={data}
      >
        <ChartBarContainer isStacked height={189} XAxisKey="mes">
          <ChartBarItem dataKey="Cancelamentos" fill="#003C52" disabledFill="#D5DADF" />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
