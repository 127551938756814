import { CpsAlert } from "corpus";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Edit1 } from "@repo/icons";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";
import {
  Item,
  ItemContent,
  ItemContentEnd,
  ItemMainContent,
  ListRoot,
} from "@/components/list";

export const PersonalizeAppointmentFormFragment = graphql(/* GraphQL */ `
  fragment PersonalizeAppointmentFormFragment on query_root {
    personalizeAppointment: usuariosProfissionaisOrientacoesSecretarias {
      professionalInfos: informacoesSobreProfissional
      appointmentInfos: informacoesSobreAgendamento
      restrictions: informacoesSobreRestricoes
      faq: informacoesDuvidasFrequentes
    }
  }
`);

export const PersonalizeAppointmentForm = ({
  data,
}: {
  data: FragmentType<typeof PersonalizeAppointmentFormFragment> | null | undefined;
}): JSX.Element => {
  const fragmentData = useFragment(PersonalizeAppointmentFormFragment, data);
  const professionalData = fragmentData?.personalizeAppointment[0];

  return (
    <>
      <PageTitleRoot>
        <PageTitleMainContent>Personalizar atendimento</PageTitleMainContent>
        <PageTitleDescription>
          Adicione informações complementares para que as secretárias possam direcionar
          seus pacientes de forma mais assertiva, garantindo agendamentos alinhados às
          suas orientações. Lembre-se de que sua agenda e configurações de atendimento já
          estão definidas.
        </PageTitleDescription>
      </PageTitleRoot>
      <div className="my-4">
        <CpsAlert
          type="info"
          title="Essas informações serão vistas apenas pelo nosso time de secretárias."
        />
      </div>
      <ListRoot>
        <Item
          linkProps={{
            to: "/settings/assist/secretary-details/professional-infos",
          }}
          noChevron
        >
          <ItemContent>
            <ItemMainContent className="text-md text-neutral-700">
              Qual seu diferencial como Profissional?
            </ItemMainContent>
            <p className="text-sm text-gray-600 font-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.professionalInfos
                ? professionalData.professionalInfos
                : "Escreva, em até 3 tópicos, os principais motivos que levam outras pessoas a te indicarem.\n Exemplos:\n 20 anos de experiência clínica. \n Atendimento humanizado com foco nas necessidades do paciente."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item
          linkProps={{
            to: "/settings/assist/secretary-details/appointment-infos",
          }}
          noChevron
        >
          <ItemContent>
            <ItemMainContent className="text-md text-neutral-700">
              Orientações para Agendamento
            </ItemMainContent>
            <p className="text-sm text-gray-600 font-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.appointmentInfos
                ? professionalData.appointmentInfos
                : "Informe como prefere que seus agendamentos sejam organizados. Use direcionamentos objetivos para facilitar o entendimento e a execução pelas secretárias.\n Exemplos: \n Concentrar novos agendamentos em datas que já possuem atendimento presencial. \n Evitar agendar consultório virtual seguido de agendamentos presenciais na Livance."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item
          linkProps={{ to: "/settings/assist/secretary-details/restrictions" }}
          noChevron
        >
          <ItemContent>
            <ItemMainContent className="text-md text-neutral-700">
              Restrições de Agendamento
            </ItemMainContent>
            <p className="text-sm text-gray-600 font-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.restrictions
                ? professionalData.restrictions
                : "Liste aqui situações ou práticas que devem ser evitadas ao contatar você ou organizar seus agendamentos.\n Exemplo: \n Não avisar sobre atraso de paciente."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
        <Item linkProps={{ to: "/settings/assist/secretary-details/faq" }} noChevron>
          <ItemContent>
            <ItemMainContent className="text-md text-neutral-700">
              Anotações de Agendamento
            </ItemMainContent>
            <p className="text-sm text-gray-600 font-normal mt-2 line-clamp-3 whitespace-pre-line">
              {professionalData?.faq
                ? professionalData.faq
                : "Campo para a secretaria registrar mais informações e anotações relevantes sobre o seu consultório.\n Exemplos: \n Dr. emite nota fiscal única. \n Informar aos pacientes que o pagamento deve ser feito antes do check-in."}
            </p>
          </ItemContent>
          <ItemContentEnd className="self-start">
            <Edit1 size={24} />
          </ItemContentEnd>
        </Item>
      </ListRoot>
    </>
  );
};
