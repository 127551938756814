import { Exclamation } from "@repo/icons";
import { useRouter, useSearch } from "@tanstack/react-router";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

export const PaymentStatusChangeDrawer = (): JSX.Element => {
  const { action, payment } = useSearch({
    from: "/settings/payments/patient-payments/details",
  });
  const router = useRouter();
  const isOpen = action === "payment-status-change";

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && isOpen) {
      router.history.back();
    }
  };

  const handlePaymentStatusChange = (): void => {
    const { checkout } = payment;

    void router.navigate({
      to: "/settings/payments/patient-payments/$guid/change-payment-status",
      params: { guid: String(checkout?.guid) },
      replace: true,
    });
  };

  return (
    <MessageDrawerRoot open={isOpen} setOpen={handleDrawerDismiss}>
      <div className="bg-danger-50 rounded-full w-[72px] h-[72px] justify-center items-center self-center flex mb-6">
        <Exclamation size={40} className="fill-danger-400" />
      </div>
      <MessageDrawerTitle>Deseja alterar o status do pagamento?</MessageDrawerTitle>
      <MessageDrawerBody>
        <p>Você poderá alterar manualmente o Status do pagamento para ”Cancelado”</p>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={handlePaymentStatusChange}>
          Alterar status do pagamento
        </MessageDrawerActionButton>
        <MessageDrawerActionButton secondary onClick={() => handleDrawerDismiss(false)}>
          Cancelar
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
