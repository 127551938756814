import { useEffect } from "react";
import { useRouteContext } from "@tanstack/react-router";
import { trackIdentify } from "@/lib/tracking";

export const useIdentifyUser = (): void => {
  const { user } = useRouteContext({ strict: false });
  const { codUsuario, codClinica, email, apelido, nome, sobrenome } = user;

  useEffect(
    function identifyUser() {
      if (codUsuario === 0) {
        return;
      }

      trackIdentify(codUsuario, nome, sobrenome, email, apelido, codClinica);
    },
    [codUsuario, codClinica, email, apelido, nome, sobrenome],
  );
};
