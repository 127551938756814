import { graphql } from "@repo/graphql-types/gql";
import { formatCurrency } from "@repo/lib";
import { UserTrackableLinkReportDetailListItem } from "@/components/user-trackable-links/report/user-trackable-link-report-detail-list-item";
import { UserTrackableLinkReportDetailAlerts } from "@/components/user-trackable-links/report/user-trackable-link-report-detail-alerts";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { formatDateToUTC } from "@/lib/date";
import { calculatePercentage } from "@/lib/math";

export const UserTrackableLinkReportDetailListItemQuery = graphql(`
  query UserTrackableLinkReportDetailListItemQuery(
    $codLinkRastreamento: String!
    $dataCadastro: String!
  ) {
    userTrackableLinkReportDetails: analyticsMembroDadosDeUsuarioLinkRastreamento(
      arg1: { codLinkRastreamento: $codLinkRastreamento, dataAcesso: $dataCadastro }
    ) {
      data {
        totalAcesso
        totalAgendamento
        totalUso
        totalRecebido
        totalBuscasRealizadas
      }
    }
  }
`);

interface UserTrackableLinkReportDetailListItemData {
  totalAcesso?: number;
  totalAgendamento?: number;
  totalUso?: number;
  totalRecebido?: string | null;
  totalBuscasRealizadas?: number | null;
}

interface UserTrackableLinkReportDetailListItem {
  name: string;
  value: string;
  percentage?: number;
}

export interface UserTrackableLinkReportDetailListProps {
  codLinkRastreamento: string;
  dataCadastro: string;
}

export const UserTrackableLinkReportDetailList = ({
  codLinkRastreamento,
  dataCadastro,
}: UserTrackableLinkReportDetailListProps): JSX.Element => {
  const queryResult = useGraphQL(UserTrackableLinkReportDetailListItemQuery, {
    codLinkRastreamento,
    dataCadastro: formatDateToUTC(dataCadastro),
  });

  const { data } = queryResult;

  const userTrackableLinkReportDetailListItemData:
    | UserTrackableLinkReportDetailListItemData
    | undefined = queryResult.data?.userTrackableLinkReportDetails?.data[0] ?? undefined;

  const totalAccess = userTrackableLinkReportDetailListItemData?.totalAcesso ?? 0;
  const totalScheduleSearch =
    userTrackableLinkReportDetailListItemData?.totalBuscasRealizadas ?? 0;

  const totalAppointments =
    userTrackableLinkReportDetailListItemData?.totalAgendamento ?? 0;
  const totalRoomsUsage = userTrackableLinkReportDetailListItemData?.totalUso ?? 0;

  const totalScheduleSearchPerTotalAccessPercentage = calculatePercentage(
    totalScheduleSearch,
    totalAccess,
  );

  const appointmentsPerTotalAccessPercentage = calculatePercentage(
    totalAppointments,
    totalAccess,
  );
  const roomsUsagePerTotalAccessPercentage = calculatePercentage(
    totalRoomsUsage,
    totalAccess,
  );

  const items = userTrackableLinkReportDetailListItemData
    ? [
        {
          name: "Acesso ao link",
          value: totalAccess.toString(),
          percentage: totalAccess > 0 ? 100 : 0,
        },
        {
          name: "Datas disponíveis consultadas",
          value: totalScheduleSearch.toString(),
          percentage:
            totalScheduleSearch > 0 ? totalScheduleSearchPerTotalAccessPercentage : 0,
        },
        {
          name: "Agendamentos",
          value: totalAppointments.toString(),
          percentage: totalAccess > 0 ? appointmentsPerTotalAccessPercentage : 0,
        },
        {
          name: "Consultas realizadas",
          value: totalRoomsUsage.toString(),
          percentage: totalAccess > 0 ? roomsUsagePerTotalAccessPercentage : 0,
        },
        {
          name: "Valor recebido",
          value: formatCurrency(
            Number(userTrackableLinkReportDetailListItemData.totalRecebido),
          ),
        },
      ]
    : [];

  return (
    <AsyncDataWrapper {...queryResult}>
      {data?.userTrackableLinkReportDetails?.data[0] ? (
        <>
          {items.map((item: UserTrackableLinkReportDetailListItem) => (
            <UserTrackableLinkReportDetailListItem
              key={item.name}
              name={item.name}
              value={item.value}
              percentage={item.percentage}
            />
          ))}

          <div className="mt-2">
            <UserTrackableLinkReportDetailAlerts />
          </div>
        </>
      ) : null}
    </AsyncDataWrapper>
  );
};
