import { App as CapApp, type URLOpenListenerEvent } from "@capacitor/app";
import { type Router } from "@tanstack/react-router";
import { DEEP_LINK_ALLOWED_URLS } from "@repo/lib";
import { Browser } from "@capacitor/browser";
import { getDefaultStore } from "jotai";
import { logDeepLink } from "@/lib/breadcrumbs.ts";
import { deepLinkAtom } from "@/lib/atoms/deep-link-atom";

const store = getDefaultStore();

export const initDeepLink = async (router: Router): Promise<void> => {
  await CapApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
    void (async () => {
      logDeepLink({ location: router.latestLocation, deeplinkUrl: event.url });
      const foundKey: string | undefined = DEEP_LINK_ALLOWED_URLS.find((key: string) =>
        event.url.includes(key),
      );
      if (foundKey) {
        // Esse é um deep-link somewhat válido
        let slug = event.url.replace(foundKey, "");
        // Se não sobrou nada, filtra
        slug = slug === "" ? "/" : slug;

        // Grava no link no estado do aplicativo
        store.set(deepLinkAtom, slug);

        // Se existe um navegador aberto, fecha
        try {
          await Browser.close();
        } catch (e) {
          // eslint-disable-next-line no-console -- Console.error
          console.error(e);
        }

        // Navega para a próxima página
        await router.navigate({ to: slug });
      }
    })();
  });
};
