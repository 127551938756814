/**
 * Retorna informações do usuário
 *
 * @param member - Dados do usuário.
 * @param specialties - Lista das especialidades do usuário.
 * @returns - Retorna um objeto com informações do usuário.
 */
export const calculatePercentage = (part: number, total: number): number => {
  if (total === 0) return 0;
  return Math.round((part / total) * 100);
};

/**
 * Calcula as porcentagens dos valores fornecidos, garantindo que a soma das porcentagens seja 100%.
 *
 * @param values - Um array de números representando os valores para calcular as porcentagens.
 * @returns Um array de números representando as porcentagens arredondadas de cada valor, garantindo que a soma seja 100%.
 *
 * @example
 * // Exemplo com valores que somam 100:
 * const percentages = distributePercentages([30, 70]);
 * console.log(percentages); // [30, 70]
 *
 * @example
 * // Exemplo com arredondamento para garantir 100%:
 * const percentages = distributePercentages([1, 1, 1]);
 * console.log(percentages); // [34, 33, 33]
 */
export const distributeValuesAsPercentages = (values: number[]): number[] => {
  const total = values.reduce((sum, value) => sum + value, 0);
  if (total === 0) {
    return values.map(() => 0);
  }

  const rawPercentages = values.map((value) => (value / total) * 100);
  const roundedPercentages = rawPercentages.map((p) => Math.floor(p));
  let difference = 100 - roundedPercentages.reduce((sum, p) => sum + p, 0);

  // Distribuir a diferença, priorizando os maiores decimais
  return roundedPercentages.map((percent, index) => {
    if (difference > 0 && rawPercentages[index] % 1 > 0) {
      difference--;
      return percent + 1;
    }
    return percent;
  });
};
