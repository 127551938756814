import {
  Add,
  Bell,
  CalendarTime,
  Cash,
  CheckCircle,
  Clock,
  DoorEnter,
  Refresh,
  UserPlus,
  XCircle,
} from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import {
  Link,
  type LinkProps,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  AppointmentTypeModalityEnum,
  convertDateTimeIgnoringTimezone,
  isConsultorioVirtual,
  removeSecondsFromTimeString,
  zonedDate,
} from "@repo/lib";
import { CpsBadge } from "corpus";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { trackEvent } from "@/lib/tracking";
import { isAppointmentNeedsToBeChargedByCancellationFee } from "@/lib/flexible-pricing";
import { WaitingRoomDrawers } from "@/components/waiting-room-drawers";
import { CardContent, CardFooter, CardRoot } from "@/components/card";
import { PatientAvatar, type PatientAvatarFragment } from "@/components/patient-avatar";
import { WaitingRoomWarningLatePatient } from "@/components/waiting-room-warning-late-patient";
import { AssistFeatures } from "@/enums/assist-features";

export const WaitingRoomAppointmentMenuFragment = graphql(/* GraphQL */ `
  fragment WaitingRoomAppointmentMenuFragment on agendamentos {
    codAgendamento
    horaInicio
    horaFim
    pago
    data
    cobraCancelamento
    AgendamentosCheckin {
      codAgendamento
    }
    unidade: Unidade {
      codUnidade
    }
    compromisso: UsuarioCompromisso {
      codUsuarioCompromissoModalidade
    }
    paciente: Paciente {
      codPaciente
      nome
      ...PatientAvatarFragment
    }
    atraso: notificacoesPacientesAtrasados(order_by: { dataCadastro: desc }) {
      tempoAtraso
      dataCadastro
    }
    tags {
      dataCadastro
      codAgendamentoTipoTag
    }
    followUpAppointment: lembretes(where: { ativo: { _eq: true } }) {
      codLembrete
    }
  }
`);

export interface WaitingRoomMenuPageProps {
  data: FragmentType<typeof WaitingRoomAppointmentMenuFragment>;
}

export const WaitingRoomAppointmentMenu = ({
  data,
}: WaitingRoomMenuPageProps): JSX.Element => {
  const appointment = useFragment(WaitingRoomAppointmentMenuFragment, data);

  const {
    user: userContext,
    ldClient,
    flags,
  } = useRouteContext({
    strict: false,
  });

  const router = useRouter();

  const searchParams = useSearch({
    from: "/waiting-room/$codAgendamento/menu/",
  });

  const isCreateOnlinePaymentEnabled = flags["ativa-fluxo-de-criar-pagamento-online"];
  const isFollowUpAppointmentEnabled = userContext.funcionalidadesContratadas.some(
    (item) => item.codFuncionalidade === AssistFeatures.PRM.valueOf() && item.ativo,
  );
  const showRecommendationInWaitingRoom =
    flags["exibe-botoes-de-indicacao-de-membros-para-pacientes"];

  const {
    codAgendamento,
    paciente,
    unidade,
    pago,
    compromisso,
    horaInicio,
    horaFim,
    data: appointmentDate,
    AgendamentosCheckin,
    cobraCancelamento,
    atraso,
    tags,
    followUpAppointment,
  } = appointment;

  const shouldShowFollowUpAppointment =
    followUpAppointment.length === 0 && isFollowUpAppointmentEnabled;

  const isAppointmentInThePastOrPresent = (): boolean => {
    const currentDate = zonedDate(new Date());

    const appointmentHoraInicio = convertDateTimeIgnoringTimezone(
      appointmentDate,
      horaInicio,
    );

    return currentDate.getTime() > appointmentHoraInicio.getTime();
  };

  const shouldDisplayChangeAppointmentOptions = !isAppointmentInThePastOrPresent();

  const shouldDisplaySwapPatientOption = !pago && !AgendamentosCheckin;

  const isTeleconsulta =
    compromisso?.codUsuarioCompromissoModalidade ===
    AppointmentTypeModalityEnum.Teleconsulta;

  const isAppointmentInConsultorioVirtual = isConsultorioVirtual(unidade.codUnidade);

  const isAppointmentTreated = appointment.tags.length > 0;

  const handleDrawerChange = (action: string): void => {
    if (searchParams.action !== action) {
      void router.navigate({
        to: "/waiting-room/$codAgendamento/menu",
        search: { ...searchParams, action, appointmentId: codAgendamento },
        params: { codAgendamento: codAgendamento.toString() },
      });
    } else if (searchParams.action === action) {
      router.history.back();
    }
  };

  const handleTreatedAppointmentDrawer = (): void => {
    handleDrawerChange("treated-appointment-drawer");
  };

  const handleUntreatedAppointmentDrawer = (): void => {
    handleDrawerChange("untreated-appointment-drawer");
  };

  const handleAppointmentCancel = (): void => {
    trackEvent("Cancelar agendamento selecionado", {
      agendamento: codAgendamento,
    });

    if (userContext.altaTaxaCancelamento) {
      trackEvent("Alerta Frequencia Cancelamento Visualizado", {
        codUsuario: userContext.codUsuario,
        dataHora: new Date().toISOString(),
        telaOrigem: "Drawer Cancelamento",
      });
    }
    handleDrawerChange("cancel-appointment");
  };

  const handleCancellationAction = (): void => {
    const shouldDisplayCancellationAlertDrawer =
      cobraCancelamento &&
      isAppointmentNeedsToBeChargedByCancellationFee(ldClient, appointmentDate);

    if (shouldDisplayCancellationAlertDrawer) {
      void router.navigate({
        search: {
          action: "cancel-flexible-price-appointment",
          appointmentId: codAgendamento,
        },
      });
    } else {
      handleAppointmentCancel();
    }
  };

  const appointmentMenuItems: MenuListItem[] = createAppointmentsMenu();

  const othersMenuItems: MenuListItem[] = createOthersMenu();

  const sendPaymentRoute: LinkProps["to"] = isCreateOnlinePaymentEnabled
    ? "/waiting-room/$codAgendamento/choose-payment-channel"
    : "/waiting-room/$codAgendamento/send-totem-payment";

  const paymentsMenuItems: MenuListItem[] = [
    ...((isAppointmentInConsultorioVirtual || isTeleconsulta) && pago
      ? []
      : [
          {
            name: "Enviar pagamento",
            Icon: Cash,
            route: sendPaymentRoute,
            params: { codAgendamento },
          },
        ]),
  ];

  const isModalOverlayOpen: boolean =
    searchParams.action === "expand-avatar-image" && searchParams.id === codAgendamento;

  const openModalOverlay = (): void => {
    void router.navigate({
      to: "/waiting-room/$codAgendamento/menu",
      search: {
        ...searchParams,
        action: "expand-avatar-image",
        id: codAgendamento,
      },
      params: { codAgendamento: codAgendamento.toString() },
    });
  };

  const closeModalOverlay = (): void => {
    if (isModalOverlayOpen) {
      router.history.back();
    }
  };

  return (
    <>
      <CardRoot>
        <CardContent>
          <div className="flex flex-row gap-x-2">
            <PatientAvatar
              shouldOpenModalOverlay
              isModalOverlayOpen={isModalOverlayOpen}
              setIsModalOverlayOpen={() => {
                if (isModalOverlayOpen) {
                  closeModalOverlay();
                } else {
                  openModalOverlay();
                }
              }}
              data={paciente as FragmentType<typeof PatientAvatarFragment>}
              color="primaryLight"
              showCheckBadge={Boolean(tags.length > 0)}
            />
            <Link
              to="/patients/$patientId"
              params={{ patientId: String(paciente?.codPaciente) }}
              className="flex px-2 flex-col"
            >
              <div className="flex flex-col">
                <p className="font-medium text-neutral-600">{paciente?.nome}</p>
                <div className="flex flex-row gap-x-4">
                  <p className="text-sm font-normal text-neutral-500">
                    {removeSecondsFromTimeString(horaInicio)} às{" "}
                    {removeSecondsFromTimeString(horaFim)}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </CardContent>
        {atraso.length > 0 && (
          <CardFooter className="p-0 block">
            <WaitingRoomWarningLatePatient lateTime={atraso[0].tempoAtraso} />
          </CardFooter>
        )}
      </CardRoot>

      {paymentsMenuItems.length > 0 ? (
        <MenuList title="Pagamentos" menuItems={paymentsMenuItems} />
      ) : null}
      <MenuList title="Agendamentos" menuItems={appointmentMenuItems} />
      <MenuList title="Outros" menuItems={othersMenuItems} />
      <WaitingRoomDrawers />
    </>
  );

  function createOthersMenu(): MenuListItem[] {
    const othersSection: MenuListItem[] = [];
    if (isAppointmentTreated) {
      othersSection.push({
        name: "Marcar como não atendido",
        Icon: CheckCircle,
        onClick: handleUntreatedAppointmentDrawer,
        params: { codAgendamento },
      });
    } else {
      othersSection.push({
        name: "Marcar como atendido",
        Icon: CheckCircle,
        onClick: handleTreatedAppointmentDrawer,
        params: { codAgendamento },
      });
    }
    if (showRecommendationInWaitingRoom) {
      othersSection.push({
        name: "Indicar profissional",
        Icon: UserPlus,
        route: "/recommendation",
        search: { patientId: paciente?.codPaciente },
      });
    }
    return othersSection;
  }

  function createAppointmentsMenu(): MenuListItem[] {
    const appointmentSection: MenuListItem[] = [];

    if (shouldShowFollowUpAppointment) {
      appointmentSection.push({
        name: "Lembrete de retorno",
        Icon: Bell,
        route: "/waiting-room/follow-up-appointment-reminder/$appointmentId",
        params: { appointmentId: codAgendamento },
        tag: <CpsBadge color="helper">Novo</CpsBadge>,
      });
    }

    if (shouldDisplayChangeAppointmentOptions) {
      appointmentSection.push(
        {
          name: "Alterar horário",
          Icon: Clock,
          route: "/waiting-room/$codAgendamento/change-time",
          params: { codAgendamento },
        },
        {
          name: "Receber sala agora",
          Icon: DoorEnter,
          route: "/waiting-room/$codAgendamento/receive-room-now",
          params: { codAgendamento },
        },
      );
      if (shouldDisplaySwapPatientOption) {
        appointmentSection.push({
          name: "Alterar paciente",
          Icon: Refresh,
          route: "/appointment/$appointmentId/patient-swap-search",
          params: { appointmentId: codAgendamento },
        });
      }
      appointmentSection.push({
        name: "Reagendar",
        Icon: CalendarTime,
        route: "/appointment/$appointmentId/reschedule",
        params: { appointmentId: codAgendamento },
      });
    }

    appointmentSection.push({
      name: "Novo agendamento",
      Icon: Add,
      route: "/appointment/create",
      search: {
        appointmentId: codAgendamento,
        patientId: paciente?.codPaciente,
      },
    });
    if (shouldDisplayChangeAppointmentOptions) {
      appointmentSection.push({
        name: "Cancelar agendamento",
        Icon: XCircle,
        onClick: handleCancellationAction,
        params: { codAgendamento },
        variant: "danger",
      });
    }

    return appointmentSection;
  }
};
