import { useRouter, useSearch } from "@tanstack/react-router";
import { SingleNumberChart } from "@/components/charts/single-number-chart";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { trackEvent } from "@/lib/tracking";
import { DashboardChartSection } from "@/components/charts/dashboard-chart-section";
import { ChartLegendItem } from "@/components/charts/chart-legend-item";

interface RescuedNoShowAppointmentsSectionProps {
  data?: {
    month: string;
    rescuedAppointmentType: string;
    appointmentsRescuedSum?: number;
    sentMessagesSum?: number;
  };
}

export const RescuedNoShowAppointmentsSection = ({
  data,
}: RescuedNoShowAppointmentsSectionProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });

  const onClick = (): void => {
    searchParams.showRescuedNoShowAppointmentsDrawer = true;

    trackEvent("Definição Gráfico Aberta", {
      grafico: "Faltas recuperadas",
    });

    void router.navigate({ search: searchParams });
  };

  const displayText =
    data?.appointmentsRescuedSum && data.appointmentsRescuedSum > 1
      ? "Faltas recuperadas"
      : "Falta recuperada";

  const legendText =
    data?.sentMessagesSum && data.sentMessagesSum > 1
      ? "Mensagens enviadas"
      : "Mensagem enviada";

  if (!data) {
    return (
      <DashboardChartSection chartTitle="Faltas recuperadas" onClick={onClick}>
        <DashboardSectionEmptyState />
        <RescuedNoShowAppointmentsDrawer />
      </DashboardChartSection>
    );
  }

  return (
    <DashboardChartSection chartTitle="Faltas recuperadas" onClick={onClick}>
      <SingleNumberChart
        totalNumber={data.appointmentsRescuedSum ?? 0}
        displayText={displayText}
      />
      <ChartLegendItem
        value={data.sentMessagesSum?.toString() ?? "0"}
        displayText={legendText}
        index={1}
      />
      <RescuedNoShowAppointmentsDrawer />
    </DashboardChartSection>
  );
};

const RescuedNoShowAppointmentsDrawer = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });
  const router = useRouter();

  return (
    <DashboardInformationalDrawer
      open={Boolean(searchParams.showRescuedNoShowAppointmentsDrawer)}
      drawerTitle="Faltas recuperadas"
      onOpenChange={(open) => {
        if (!open) {
          void router.navigate({
            search: {
              showRescuedNoShowAppointmentsDrawer: undefined,
            },
            replace: true,
          });
        }
      }}
    >
      Para pacientes que faltaram e não remarcaram, enviamos uma mensagem convidando-os a
      realizar um novo agendamento. Este gráfico mostra quantos realizaram o agendamento
      após receberem nossa mensagem.
    </DashboardInformationalDrawer>
  );
};
