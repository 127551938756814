interface SingleNumberChartHeaderProps {
  totalNumber: number;
  displayText: string;
}

export const SingleNumberChart = ({
  totalNumber,
  displayText,
}: SingleNumberChartHeaderProps): JSX.Element => {
  return (
    <div className="text-center flex flex-col mt-3">
      <div className="flex flex-row justify-center items-center gap-1">
        <span className="font-semibold text-2xl">{totalNumber}</span>
      </div>
      <p className="text-sm text-neutral-500">{displayText}</p>
    </div>
  );
};
