//TODO: Passar para a tabela de motivo_nao_agendamento
export const noSchedulingReasonMap: Record<number, string> = {
  0: "Motivo não informado",
  1: "Paciente parou de responder",
  2: "Paciente queria convênio",
  3: "Paciente desejava outros horários",
  4: "Indisponibilidade em sua agenda",
  5: "Restrição do canal agendamento",
  6: "Valor do atendimento",
  8: "Paciente marcou sozinho",
  9: "Faltava dados do convênio",
  10: "Paciente desejava exames prévios",
  11: "Paciente procurava outra especialidade",
  12: "Paciente procurava outro atendimento",
  13: "Idade do paciente",
  15: "Paciente preferiu não agendar no momento",
  16: "Localização",
  17: "Caso de pronto-socorro",
  18: "Falta de encaminhamento",
  19: "Outros motivos",
  20: "Paciente irá verificar o reembolso",
};
