import clsx from "clsx";
import type { ReactNode } from "react";

export interface CpsModalOverlayProps {
  show: boolean;
  onClick: () => void;
  children: ReactNode;
}

/**
 * `CpsModalOverlay` is a component that renders a modal overlay.
 *
 *
 * @example
 * ```tsx
 * <CpsModalOverlay show={true} onClick={() => setShow(false)}>
 *     <p>Modal overlay content</p>
 * </CpsModalOverlay>
 * ```
 * @param show - Whether the modal overlay is visible.
 * @param onClick - The function to be called when the modal overlay is clicked.
 * @param children - The content of the modal overlay.
 *
 */
export const CpsModalOverlay = ({
  show,
  onClick,
  children,
}: CpsModalOverlayProps): JSX.Element => {
  return (
    <div
      className={clsx("fixed left-0 top-0 h-full w-full z-10", show ? "fixed" : "hidden")}
      aria-modal="true"
      role="alertdialog"
    >
      <div
        className="fixed left-0 top-0 z-50 h-full w-full bg-black opacity-75 transition-all duration-500"
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
      />
      {children}
    </div>
  );
};

export default CpsModalOverlay;
