import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";

/**
 * Preenche os meses não existentes na lista de períodos com valores padrão.
 *
 * @param monthsList - Lista de meses que devem estar presentes nos dados.
 * @param periodData - Dados do período que podem ter meses não existentes.
 * @returns Uma nova lista de dados do período com todos os meses presentes, preenchidos com valores padrão onde necessário.
 */
export const fillMissingPeriodData = (
  monthsList: string[],
  periodData: DataItemRelatorioIndependente[],
): DataItemRelatorioIndependente[] => {
  const defaultData: DataItemRelatorioIndependente = {
    agendamentosMarcados: 0,
    agendamentosNovos: 0,
    agendamentosPacienteAtrasado: 0,
    cancelamentosDefinitivos: 0,
    consultasNoShow: 0,
    consultasPresenciais: 0,
    consultasRealizadas: 0,
    consultasVirtuais: 0,
    reagendamentos: 0,
  };

  return monthsList.map((month) => {
    return (
      periodData.find((item) => item.mes === month) ?? {
        ...defaultData,
        mes: month,
      }
    );
  });
};
