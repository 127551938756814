import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type CustomerContactDashboardTotalContactsSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";

export const CustomerContactDashboardTotalContactsSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment CustomerContactDashboardTotalContactsSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      totalCustomerContacts
      totalCustomerContactsVoice
      percentCustomerContactsVoice
      totalCustomerContactsChat
      percentCustomerContactsChat
      period
    }
  `,
);

interface CustomerContactDashboardTotalContactsSectionProps {
  data: FragmentType<typeof CustomerContactDashboardTotalContactsSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardTotalContactsSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardTotalContactsSectionProps): JSX.Element => {
  const fragmentData = useFragment(
    CustomerContactDashboardTotalContactsSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Total de contatos",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.totalCustomerContacts !== 0,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Total de contatos
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={currentMonthData?.totalCustomerContacts ?? 0}
          />
          <CustomerContactDashboardTotalContactsSectionChart
            totalCustomerContactsData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showCustomerContactsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Total de contatos"
      >
        <p>Total de contatos recebidos nos canais de atendimento, segmentados por:</p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-bold ">Telefone: </span>
            <span>Ligações recebidas e realizadas.</span>
          </li>
          <li className="mb-1 list-item">
            <span className="font-bold ">WhatsApp: </span>
            <span>Conversas abertas.</span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface CustomerContactDashboardTotalContactsSectionChartProps {
  totalCustomerContactsData: readonly CustomerContactDashboardTotalContactsSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardTotalContactsSectionChart = ({
  totalCustomerContactsData,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardTotalContactsSectionChartProps): JSX.Element => {
  const data = totalCustomerContactsData.map(
    ({ totalCustomerContactsChat, totalCustomerContactsVoice, period }) => ({
      WhatsApp: totalCustomerContactsChat,
      Telefone: totalCustomerContactsVoice,
      mes: period,
    }),
  );

  const labels = totalCustomerContactsData.map(
    ({ percentCustomerContactsChat, percentCustomerContactsVoice }) => ({
      WhatsApp:
        percentCustomerContactsChat >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${percentCustomerContactsChat}%`
          : "",
      Telefone:
        percentCustomerContactsVoice >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${percentCustomerContactsVoice}%`
          : "",
    }),
  );

  return (
    <div data-testid="professional-customer-contact-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Telefone" fill="#72ABBC" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="WhatsApp" fill="#003C52" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
