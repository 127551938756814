import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgStarCheckMark = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 16 17"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.885 12.938H4.23c-.37 0-.669-.3-.669-.669v-1.654a.669.669 0 0 0-.196-.473l-1.17-1.17a.668.668 0 0 1 0-.945l1.182-1.182a.627.627 0 0 0 .184-.444V4.73c0-.37.3-.669.669-.669h1.654c.178 0 .348-.07.473-.196l1.17-1.17a.668.668 0 0 1 .945 0l1.17 1.17a.669.669 0 0 0 .473.196h1.655c.369 0 .668.3.668.669v1.654c0 .178.071.348.196.473l1.17 1.17a.668.668 0 0 1 0 .945l-1.17 1.17a.669.669 0 0 0-.196.473v1.655c0 .369-.299.668-.668.668h-1.655a.669.669 0 0 0-.473.196l-1.17 1.17a.668.668 0 0 1-.945 0l-1.17-1.17a.664.664 0 0 0-.473-.197"
        clipRule="evenodd"
      />
      <path d="M10 7.5 7.5 10 6 8.5" />
    </svg>
  );
};
export default SvgStarCheckMark;
