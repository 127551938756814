import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { Bell } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import { ListRoot } from "@/components/list";
import { FollowUpAppointmentReminderListItem } from "@/components/follow-up-appointment-reminder-list-item";
import {
  EmptyStateRoot,
  EmptyStateIcon,
  EmptyStateTitle,
  EmptyStateDescription,
} from "@/components/empty-state";

const FollowUpAppointmentReminderListQuery = graphql(/* GraphQL */ `
  fragment FollowUpAppointmentReminderListQuery on query_root {
    lembretes(
      where: {
        codTipoLembrete: { _eq: $codTipoLembreteRetorno }
        ativo: { _eq: true }
        dataEnviado: { _is_null: true }
        dataParaEnvio: { _gte: $hoje }
      }
      order_by: { dataParaEnvio: asc }
    ) {
      codLembrete
      ...FollowUpAppointmentReminderListItemQuery
    }
  }
`);

interface FollowUpAppointmentReminderListProps {
  data: FragmentType<typeof FollowUpAppointmentReminderListQuery>;
}

export const FollowUpAppointmentReminderList = ({
  data,
}: FollowUpAppointmentReminderListProps): JSX.Element => {
  const queryData = useFragment(FollowUpAppointmentReminderListQuery, data);

  return (
    <ListRoot>
      {queryData.lembretes.length ? (
        queryData.lembretes.map((lembrete) => (
          <FollowUpAppointmentReminderListItem
            key={lembrete.codLembrete}
            data={lembrete}
          />
        ))
      ) : (
        <EmptyStateRoot>
          <EmptyStateIcon icon={Bell} fill="primary" />
          <EmptyStateTitle>Você ainda não tem lembretes programados</EmptyStateTitle>
          <EmptyStateDescription>
            Configure os lembretes de retorno em:
            <div className="flex justify-center">
              <ol className="list-decimal text-justify space-y-2 max-w-md mx-auto pt-2">
                <li>
                  Acesse a{" "}
                  <Link to="/waiting-room" replace>
                    <span className="text-[#0080a5] underline">
                      Sala de Espera Virtual
                    </span>
                  </Link>
                </li>
                <li>Selecione o paciente atendido</li>
                <li>Clique em Lembrete de Retorno</li>
              </ol>
            </div>
          </EmptyStateDescription>
        </EmptyStateRoot>
      )}
    </ListRoot>
  );
};
