import {
  MapDaysOfWeekToFullName,
  convertDateTimeIgnoringTimezone,
  format,
  formatCurrency,
} from "@repo/lib";
import { InfoSection } from "@/components/info-section";
import { formatTimeHourMinute, parseTime } from "@/lib/time";

interface ReservedScheduleCancelSimulationInfoProps {
  locationName?: string | null;
  date?: string;
  dayOfWeek?: number;
  startTime?: string;
  endTime?: string;
  tax: number;
  cost: number;
}

export const ReservedScheduleCancelSimulationInfo = ({
  date,
  locationName,
  dayOfWeek,
  startTime,
  endTime,
  tax,
  cost,
}: ReservedScheduleCancelSimulationInfoProps): JSX.Element => {
  const dayOfWeekStr = dayOfWeek ? MapDaysOfWeekToFullName.get(dayOfWeek) : "";

  const timePeriod =
    startTime && endTime
      ? `${formatTimeHourMinute(parseTime(startTime))} -
         ${formatTimeHourMinute(parseTime(endTime))}`
      : "";

  const nextDate = date
    ? format(convertDateTimeIgnoringTimezone(date), "dd 'de' MMMM")
    : " ";

  const chargeDetails = {
    title: "",
    fields: [
      { label: "Cobrança relativa ao dia", value: nextDate },
      {
        label: `Valor da taxa (${tax * 100}%)`,
        value: formatCurrency(cost),
      },
    ],
  };

  return (
    <div className="px-4 py-6 bg-neutral-50 gap-6 rounded-40 w-full">
      <p className="font-medium">
        {Boolean(locationName) && `${locationName}, `}
        {Boolean(dayOfWeekStr) && `${MapDaysOfWeekToFullName.get(dayOfWeek ?? 0)}, `}
        {Boolean(timePeriod) && timePeriod}
      </p>
      <InfoSection section={chargeDetails} includeLastLine={false} lineColorLevel={200} />
    </div>
  );
};
