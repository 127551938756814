import { CpsBadge, type CpsBadgeProps } from "corpus";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { format } from "date-fns";
import {
  cn,
  formatCurrencyFromCentsToReais,
  formatWithZonedDate,
  removeSecondsFromTimeString,
} from "@repo/lib";
import { useAtomValue } from "jotai";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";

export const PatientPaymentsListItemFragment = graphql(/* GraphQL */ `
  fragment PatientPaymentsListItemFragment on ExpensumGetPaymentInfo {
    status
    origem
    note
    tipoAtividade
    meioPagamentoSelecionado
    valor
    criadoEm
    pagoEm
    agendamento {
      data
      horaInicio
      unidade
      paciente {
        nome
      }
    }
    checkout {
      guid
      nomeDoPagador
      emailDoPagador
      telefoneDoPagador
    }
    parcelas
    acquirerTransactionKey
  }
`);

interface PatientPaymentListItemProps {
  data: FragmentType<typeof PatientPaymentsListItemFragment> | null;
}

export const PatientPaymentListItem = ({
  data,
}: PatientPaymentListItemProps): JSX.Element => {
  const fragmentResult = useFragment(PatientPaymentsListItemFragment, data);

  const isAmountVisible = useAtomValue(amountVisibilityAtom);

  const buildPaymentMethodInformation = (): string => {
    const installments = fragmentResult?.parcelas;
    const paymentType = fragmentResult?.meioPagamentoSelecionado;

    const buildCreditLabel = (): string => {
      if (!installments) return "Crédito";

      return `Crédito em ${installments}x`;
    };

    const paymentMethodsDictionary: Record<string, string> = {
      credito: buildCreditLabel(),
      debito: "Débito",
      pix: "PIX",
    };

    return paymentMethodsDictionary[paymentType ?? ""] ?? "";
  };

  const paymentMethodInfo = buildPaymentMethodInformation();

  const buildAmountText = (isPaymentRefunded: boolean, amount?: number): string => {
    if (!isAmountVisible) {
      return `${isPaymentRefunded ? "-" : ""}R$ \u2022\u2022\u2022\u2022\u2022`;
    }

    if (!amount) return "R$ 0,00";

    const adjustedAmount = isPaymentRefunded ? amount * -1 : amount;

    return formatCurrencyFromCentsToReais(adjustedAmount);
  };

  const buildBadge = (): JSX.Element => {
    const status = fragmentResult?.status;

    const badgeDictionary: Record<
      string,
      {
        text: string;
        color: CpsBadgeProps["color"];
      }
    > = {
      paid: { text: "Pago", color: "success" },
      pending: { text: "Em Aberto", color: "helper" },
      refunded: { text: "Estornado", color: "danger" },
      canceled: { text: "Cancelado", color: "warning" },
    };

    const { text, color } = badgeDictionary[status ?? "pending"];

    return (
      <CpsBadge data-align="end" color={color}>
        {text}
      </CpsBadge>
    );
  };

  const buildDateInformation = (): string => {
    const agendamento = fragmentResult?.agendamento;

    if (agendamento) {
      const date = agendamento.data;
      const horaInicio = agendamento.horaInicio;

      const formattedData = date
        ? format(new Date(`${date}T00:00:00-03:00`), "dd/MM/yyyy")
        : "";

      const formattedTime = horaInicio ? removeSecondsFromTimeString(horaInicio) : "";

      return `Agendamento em ${formattedData} ${formattedTime}`;
    }

    if (fragmentResult?.criadoEm) {
      const criadoEm = fragmentResult.criadoEm;
      return `Pagamento criado em ${formatWithZonedDate(criadoEm, "dd/MM/yyyy HH:mm")}`;
    }

    return "";
  };

  const isPaymentRefunded = fragmentResult?.status === "refunded";
  const lineThroughAmount = fragmentResult?.status === "canceled";

  return (
    <Item
      linkProps={{
        to: "/settings/payments/patient-payments/details",
        search: {
          payment: {
            ...fragmentResult,
          },
        },
      }}
    >
      <ItemContent>
        <p className="text-md text-gray-600 mb-1">{paymentMethodInfo}</p>
        <ItemMainContent
          className={cn(
            "mb-2",
            isPaymentRefunded && "text-danger-400",
            lineThroughAmount && "line-through text-neutral-400",
          )}
        >
          {buildAmountText(isPaymentRefunded, fragmentResult?.valor)}
        </ItemMainContent>
        <p className="text-md font-medium text-gray-800">
          {fragmentResult?.agendamento?.paciente.nome ?? ""}
        </p>
        <p className="text-md text-gray-600 mb-1">{buildDateInformation()}</p>
      </ItemContent>
      {buildBadge()}
    </Item>
  );
};
