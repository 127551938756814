import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { format } from "date-fns-tz";
import { endOfDay, endOfMonth, isBefore, startOfMonth } from "date-fns";
import { CpsSpinner } from "corpus";
import {
  FIVE_MINUTES_STALE_TIME,
  MONTHS_TO_DISPLAY,
} from "@/lib/constants/dashboards-customer-overview";
import { HeaderRoot, HeaderUpButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { CustomerContactDashboardContactsDistributionSection } from "@/components/customer-contact-overview/customer-contact-dashboard-contacts-distribution-section";
import { CustomerContactDashboardContactReasonSection } from "@/components/customer-contact-overview/customer-contact-dashboard-contact-reason-section";
import { CustomerContactDashboardSchedulingConversionRateSection } from "@/components/customer-contact-overview/customer-contact-dashboard-scheduling-conversion-rate-section";
import { CustomerContactDashboardReasonsForNotSchedulingSection } from "@/components/customer-contact-overview/customer-contact-dashboard-reasons-for-not-scheduling-section";
import { CustomerContactDashboardHumanContactsSection } from "@/components/customer-contact-overview/customer-contact-dashboard-human-contacts-section-props";
import { DashboardTimePeriodSelector } from "@/components/dashboard/dashboard-time-period-selector";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { useGraphQL } from "@/hooks/use-graphql";
import { CustomerContactDashboardTotalContactsSection } from "@/components/customer-contact-overview/customer-contact-dashboard-total-contacts-section";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { getFormattedMonthsArray, getMonthDateFromFormattedString } from "@/lib/date";
import { trackEvent } from "@/lib/tracking";
import { CheckOtherDashboardButton } from "@/components/dashboard/check-other-dashboard-button";
import { CustomerContactStoriesBanner } from "@/components/customer-contact-overview/customer-contact-stories-banner";
import { useInitialDateForOverviews } from "@/hooks/use-initial-date-for-overviews";

const CustomerContactOverviewPageQuery = graphql(/* GraphQL */ `
  query CustomerContactOverviewPageQuery($from: String!, $to: String!) {
    NuntiusGetCustomerContactManyPeriodSummary(arg1: { from: $from, to: $to }) {
      ...CustomerContactDashboardTotalContactsSectionQueryFragment
      ...CustomerContactDashboardContactsDistributionSectionQueryFragment
      ...CustomerContactDashboardSchedulingConversionRateSectionQueryFragment
      ...CustomerContactDashboardReasonsForNotSchedulingSectionQueryFragment
      ...CustomerContactDashboardHumanContactsSectionQueryFragment
      ...CustomerContactDashboardContactReasonSectionQueryFragment
      period
    }
  }
`);

export const CustomerContactOverviewPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const dataCollectionStart = new Date(2024, 10);

  const memberFirstMonth = user.dataValidacao ?? dataCollectionStart;

  const earliestDate = isBefore(memberFirstMonth, dataCollectionStart)
    ? dataCollectionStart
    : memberFirstMonth;
  const formattedEarliestDate = format(earliestDate, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const endOfToday = endOfDay(new Date());

  const toDate = format(endOfToday, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const monthsList = getFormattedMonthsArray(formattedEarliestDate, toDate);
  const fromDate = useInitialDateForOverviews(endOfToday);
  const queryResult = useGraphQL(
    CustomerContactOverviewPageQuery,
    {
      from: fromDate,
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
    },
  );

  const { data } = queryResult;

  let periodData = data?.NuntiusGetCustomerContactManyPeriodSummary ?? [];

  const paginatedData = periodData.slice(-MONTHS_TO_DISPLAY);

  const router = useRouter();

  const searchParams: Record<string, string> = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  let selectedMonth: string = searchParams.selectedMonth
    ? String(searchParams.selectedMonth)
    : monthsList[monthsList.length - 1];

  if (!searchParams.selectedMonth || !monthsList.includes(selectedMonth)) {
    selectedMonth = monthsList[monthsList.length - 1];
    searchParams.selectedMonth = selectedMonth;

    void router.navigate({ search: searchParams, replace: true });
  }

  const shouldLoadNewMonthData =
    periodData.length > 0 && periodData.every((item) => item.period !== selectedMonth);

  let formattedFromDateNewMonth = "";
  let formattedToDateNewMonth = "";

  if (shouldLoadNewMonthData) {
    ({ formattedFromDateNewMonth, formattedToDateNewMonth } = getFormattedDatesToQuery(
      monthsList,
      String(periodData[0]?.period),
      selectedMonth,
    ));
  }

  const newPeriodDataResult = useGraphQL(
    CustomerContactOverviewPageQuery,
    {
      from: formattedFromDateNewMonth,
      to: formattedToDateNewMonth,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
      enabled: shouldLoadNewMonthData,
    },
  );

  const { data: newPeriodResult } = newPeriodDataResult;

  const newPeriodQueryData =
    newPeriodResult?.NuntiusGetCustomerContactManyPeriodSummary ?? [];

  if (newPeriodDataResult.isFetched && newPeriodResult) {
    periodData = [...newPeriodQueryData, ...periodData];
  }

  const onSelectedMonthChange = (newMonth: string): void => {
    trackEvent("Novo Mês Relatório Selecionado", {
      "Mês selecionado": newMonth,
      Origem: "Livance Assist",
      Relatorio: "Contatos",
    });

    const search = {
      ...searchParams,
    };

    search.selectedMonth = newMonth;
    void router.navigate({ search, replace: true });
  };

  const selectedMonthIndex = monthsList.indexOf(selectedMonth);

  if (
    monthsList.length > MONTHS_TO_DISPLAY &&
    selectedMonthIndex < monthsList.length - MONTHS_TO_DISPLAY &&
    newPeriodDataResult.isFetched
  ) {
    const firstDisplayedMonthIndex = monthsList.indexOf(String(paginatedData[0].period));
    for (let i = firstDisplayedMonthIndex; selectedMonthIndex < i; i--) {
      const elementToPush = periodData.find((item) => item.period === monthsList[i - 1]);

      if (elementToPush) {
        paginatedData.pop();
        paginatedData.unshift(elementToPush);
      }
    }
  }

  const renderContent = (): JSX.Element => {
    if (!data) {
      return <DashboardSectionEmptyState />;
    }

    if (shouldLoadNewMonthData && !newPeriodDataResult.isFetched) {
      return <CpsSpinner />;
    }

    return (
      <>
        <CustomerContactStoriesBanner />
        <CustomerContactDashboardTotalContactsSection
          data={paginatedData}
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <CustomerContactDashboardContactsDistributionSection
          data={paginatedData}
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <CustomerContactDashboardContactReasonSection
          data={paginatedData}
          selectedMonth={selectedMonth}
        />
        <CustomerContactDashboardSchedulingConversionRateSection
          data={paginatedData}
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <CustomerContactDashboardReasonsForNotSchedulingSection
          data={paginatedData}
          selectedMonth={selectedMonth}
        />
        <CustomerContactDashboardHumanContactsSection
          data={paginatedData}
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <CheckOtherDashboardButton
          buttonText="Ver relatório de consultas"
          targetRoute="/settings/assist/customer-appointment-overview"
          searchParams={{ selectedMonth }}
        />
      </>
    );
  };

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Relat&oacute;rio de Contatos" align="center" />
      </HeaderRoot>
      <DashboardTimePeriodSelector
        selectedMonth={searchParams.selectedMonth}
        monthsList={monthsList}
        onSelectedMonthChange={onSelectedMonthChange}
      />
      <Page>
        <AsyncDataWrapper {...queryResult}>{renderContent()}</AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/customer-contact-overview/")({
  component: CustomerContactOverviewPage,
});

function getFormattedDatesToQuery(
  monthsList: string[],
  earliestLoadedMonth: string,
  selectedMonth: string,
): { formattedFromDateNewMonth: string; formattedToDateNewMonth: string } {
  const mostRecentNotLoadedMonthString =
    monthsList[monthsList.indexOf(earliestLoadedMonth) - 1];

  const mostRecentNotLoadedMonth = getMonthDateFromFormattedString(
    mostRecentNotLoadedMonthString,
  );

  const fromDateMonth = getMonthDateFromFormattedString(selectedMonth);

  const formattedFromDateNewMonth = format(
    startOfMonth(fromDateMonth),
    "yyyy-MM-dd HH:mm:ssXXX",
    {
      timeZone: "America/Sao_Paulo",
    },
  );

  const formattedToDateNewMonth = format(
    endOfMonth(mostRecentNotLoadedMonth),
    "yyyy-MM-dd HH:mm:ssXXX",
    {
      timeZone: "America/Sao_Paulo",
    },
  );

  return {
    formattedFromDateNewMonth,
    formattedToDateNewMonth,
  };
}
