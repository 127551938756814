import { CpsInput, CpsTextArea } from "corpus";
import { useForm } from "react-hook-form";
import { type z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { graphql } from "@repo/graphql-types/gql";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { type CreateTrackableLinkMutationMutation } from "@repo/graphql-types/graphql";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { userTrackableLinksCreate } from "@/lib/form-schemas/user-trackable-links.schema";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { trackEvent } from "@/lib/tracking";
import { LinkDestinationSelectInput } from "@/components/link-destination-select-input";

const CreateTrackableLinkMutation = graphql(/* GraphQL */ `
  mutation CreateTrackableLinkMutation($input: CriarUsuarioLinkRastreamentoInput!) {
    criarUsuarioLinkRastreamento(input: $input) {
      criarUsuarioLinkRastreamentoOutput {
        codUsuarioLinkRastreamento
        link
      }
    }
  }
`);

export type UserTrackableLinksCreateValues = z.infer<typeof userTrackableLinksCreate>;

export const UserTrackableLinksCreateForm = (): JSX.Element => {
  const router = useRouter();
  const { queryClient } = useRouteContext({ strict: false });
  const { mutateAsync } = useGraphQLMutation(CreateTrackableLinkMutation);

  const currentUrl = "/settings/assist/user-trackable-links/create/";
  const searchParams = useSearch({
    from: currentUrl,
  });

  const form = useForm<UserTrackableLinksCreateValues>({
    resolver: zodResolver(userTrackableLinksCreate),
    defaultValues: {
      description: "",
    },
  });

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: currentUrl,
        search: { action: "user-trackable-links-create-form-error" },
      });
    } else if (searchParams.action === "user-trackable-links-create-form-error") {
      router.history.back();
    }
  };

  const onSubmit = async (_formData: UserTrackableLinksCreateValues): Promise<void> => {
    const onError = (): void => setShowUnexpectedErrorDrawer(true);

    const onSuccess = ({
      criarUsuarioLinkRastreamento,
    }: CreateTrackableLinkMutationMutation): void => {
      const userTrackableLinkId = String(
        criarUsuarioLinkRastreamento.criarUsuarioLinkRastreamentoOutput
          ?.codUsuarioLinkRastreamento,
      );

      trackEvent("Link Campanha Criado", {
        id: userTrackableLinkId,
        link: criarUsuarioLinkRastreamento.criarUsuarioLinkRastreamentoOutput?.link,
      });

      void queryClient.resetQueries({
        queryKey: ["UserTrackableLinksQuery"],
      });

      void router.navigate({
        to: "/settings/assist/user-trackable-links/$userTrackableLinkId/",
        params: {
          userTrackableLinkId,
        },
        replace: true,
      });
    };

    await mutateAsync(
      {
        input: {
          nome: _formData.name,
          codLinkDestino: _formData.destinationId,
          descricao: _formData.description,
        },
      },
      { onSuccess, onError },
    );
  };

  return (
    <div className="flex flex-col gap-6 max-w-2xl mx-auto w-full">
      <FormRoot {...form}>
        <FormHandlerSubmit className="w-full flex flex-col gap-6" handleSubmit={onSubmit}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title="Nome"
                    placeholder="Link da bio do instagram"
                    inputMode="text"
                    type="text"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="destinationId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <LinkDestinationSelectInput title="Direcionamento" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsTextArea
                    title="Descrição (opcional)"
                    placeholder="Foi utilizado como link da bio do instagram a partir de 18/12"
                    rows={5}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormSubmitButton type="submit">Criar link</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>

      <UnexpectedErrorDrawer
        open={searchParams.action === "user-trackable-links-create-form-error"}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </div>
  );
};
