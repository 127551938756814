import { useRouter } from "@tanstack/react-router";
import { Button } from "@/components/button";

export const PatientPaymentDetailsAbout = (): JSX.Element => {
  const router = useRouter();

  const handleClickBackButton = (): void => {
    router.history.back();
  };

  return (
    <div className="flex-col flex gap-4">
      <h1 className="font-semibold text-lg text-neutral-600">Detalhes do pagamento</h1>
      <p>
        Nesta tela, você pode visualizar informações detalhadas sobre o pagamento
        selecionado. O valor exibido refere-se ao valor bruto, ou seja, sem deduções de
        taxas. No caso de pagamentos realizados, é possível consultar as informações de
        recebimento na seção Recebimentos.
      </p>
      <p>
        Você poderá consultar diferentes informações de acordo com o status do pagamento:
      </p>

      <section className="flex-col flex gap-4">
        <h2 className="text-lg font-semibold text-neutral-600">Entenda os status:</h2>
        <p>
          <span className="font-semibold text-neutral-600">Em aberto:</span> O pagamento
          ainda não foi realizado, dessa forma, ele não estará disponível na seção de
          Recebimentos até que seja pago.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Pago:</span> O pagamento foi
          realizado com sucesso e está aprovado. É possível consultar o valor total do
          pagamento, a data e o horário em que o pagamento foi aprovado, além dos detalhes
          do agendamento.
        </p>
        <p>
          <span className="font-semibold text-neutral-600">Estornado:</span> O valor do
          pagamento foi devolvido ao paciente após o pedido do estorno. O prazo para
          estorno depende do tipo de pagamento que foi realizado e pode variar de acordo
          com o emissor do cartão.
        </p>
      </section>

      <Button onClick={handleClickBackButton}>Voltar</Button>
    </div>
  );
};
