import { useEffect } from "react";
import { useRouteContext } from "@tanstack/react-router";

export const useUserPilotInitializer = (): void => {
  const { user } = useRouteContext({ strict: false });
  const { codUsuario, email, apelido, nome, sobrenome } = user;

  useEffect(() => {
    if (codUsuario === 0) {
      return;
    }
    window.userpilot?.identify(String(codUsuario), {
      name: apelido ?? `${nome} ${sobrenome}`,
      email,
    });
  }, [codUsuario, email, apelido, nome, sobrenome]);
};
