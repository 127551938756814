import { StarCheckMark } from "@repo/icons";
import { CpsBadge } from "corpus";

export const AssistProBadge = (): JSX.Element => {
  return (
    <CpsBadge gap className="bg-secondary-400 text-secondary-400-contrast">
      <StarCheckMark className="stroke-secondary-400-contrast" size={16} />
      PRO
    </CpsBadge>
  );
};
