import { cn, DatePayableStatus, PaymentMethods } from "@repo/lib";
import { CpsBadge } from "corpus";
import { useAtomValue } from "jotai";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { type DayPayables } from "@/lib/mappers/payables";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";

interface PayablesListItemProps {
  dayPayables: DayPayables;
}

export const PayablesListItem = ({ dayPayables }: PayablesListItemProps): JSX.Element => {
  const { dayOfWeek, totalAmount } = dayPayables;

  const isAmountVisible = useAtomValue(amountVisibilityAtom);

  const buildAmountText = (amount: string): string => {
    if (!isAmountVisible) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    return amount;
  };

  const buildCreatedDateText = (payable: DayPayables["payables"][0]): string => {
    const { appointmentDate, paymentCreatedAt, createdAt } = payable;

    if (appointmentDate) {
      return `Agendamento em ${appointmentDate}`;
    } else if (paymentCreatedAt) {
      return `Pagamento criado em ${paymentCreatedAt}`;
    }

    return `Pagamento realizado em ${createdAt}`;
  };

  const buildPaymentMethodText = (
    paymentMethod: string,
    currentInstallment: number,
    installments: number,
  ): string | null => {
    const paymentMethodMap: Record<string, string> = {
      [PaymentMethods.Credit]: `Crédito parcela ${currentInstallment} de ${installments}`,
      [PaymentMethods.Debit]: "Débito",
      [PaymentMethods.Pix]: "Pix",
    };

    return paymentMethodMap[paymentMethod] || null;
  };

  const buildBadge = (status: DatePayableStatus): JSX.Element => {
    const badgeMap: Record<
      DatePayableStatus,
      "neutral" | "danger" | "warning" | "success" | "helper"
    > = {
      [DatePayableStatus.Refund]: "danger",
      [DatePayableStatus.Foreseen]: "helper",
      [DatePayableStatus.Rejected]: "danger",
      [DatePayableStatus.ToBeConfirmed]: "helper",
      [DatePayableStatus.Paid]: "success",
    };

    return (
      <CpsBadge data-align="end" color={badgeMap[status]}>
        {status}
      </CpsBadge>
    );
  };

  const paymentIsRefunded = (payableStatus: DatePayableStatus): boolean => {
    return payableStatus === DatePayableStatus.Refund;
  };

  return (
    <div className="divide-y-2 divide-y-reverse divide-neutral-100">
      <div className="pt-6">
        <p className="text-lg text-neutral-500 font-bold">{dayOfWeek}</p>
        <h3 className="text-lg text-neutral-500">
          Saldo do dia: {buildAmountText(totalAmount.formattedAmount)}
        </h3>
      </div>
      {dayPayables.payables.map((payable) => {
        return (
          <Item key={`${payable.createdAt}-${payable.id}`}>
            <ItemContent>
              <p className="text-md text-neutral-500">
                {buildPaymentMethodText(
                  payable.paymentMethod,
                  payable.installment,
                  payable.installments,
                )}
              </p>

              <ItemMainContent
                className={cn(paymentIsRefunded(payable.status) && "text-danger-400")}
              >
                {buildAmountText(payable.amount)}
              </ItemMainContent>
              <p className="text-md font-semibold text-neutral-600">
                {payable.patientName}
              </p>

              <p className="text-md text-neutral-500">{buildCreatedDateText(payable)}</p>
            </ItemContent>
            {buildBadge(payable.status)}
          </Item>
        );
      })}
    </div>
  );
};
