import { type ReactNode, createContext, useMemo } from "react";

export interface ChartContextProps {
  data: Record<string, unknown>[];
  selectedKey?: string | null;
  onSelectedKeyChange?: (key: string) => void;
  id?: string | number;
  labels?: Record<string, string | number>[];
}

export const ChartContext = createContext<ChartContextProps | undefined>(undefined);

export const ChartProvider = ({
  children,
  selectedKey,
  onSelectedKeyChange,
  data,
  id,
  labels,
}: ChartContextProps & { children: ReactNode }): JSX.Element => {
  const value = useMemo(
    () => ({ selectedKey, onSelectedKeyChange, data, id, labels }),
    [selectedKey, onSelectedKeyChange, data, id, labels],
  );

  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
};
