/* eslint-disable import/no-duplicates -- There's no way to avoid importing the locale */
import { capitalizeFirstLetter, formatWithZonedDate } from "@repo/lib";
import {
  formatDistanceStrict as dateFnsFormatDistanceStrict,
  parse,
  parseISO,
  format as formatDatefns,
  isBefore,
  addMonths,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { type DateFormatter } from "react-day-picker";

export const parseDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day);
};

export enum TimeType {
  Milliseconds = 1,
  Seconds = 1000,
  Minutes = 60000,
  Hours = 3600000,
  Days = 86400000,
}

export const dateDiff = (start: Date, end: Date, timeType: TimeType): number => {
  return (end.getTime() - start.getTime()) / timeType;
};

export const parseDateTime = (date: string, time: string): Date => {
  return parse(`${date} ${time}`, "yyyy-MM-dd HH:mm:ss", new Date());
};

export const formatDistanceStrict = (date: Date, baseDate: Date): string => {
  return dateFnsFormatDistanceStrict(date, baseDate, {
    locale: ptBR,
    unit: "minute",
    roundingMethod: "floor",
  });
};

/**
 * Recebe uma data e formata para que seja exibido o mês com inicial em maiúsculo e concatenando o ano.
 * @param date - Data a ser formatada.
 * @returns O mês com a primeira letra em maiúsculo e concatenado com o ano.
 */
export const formatMonthUpperInitial: DateFormatter = (date: Date) => {
  const formattedDate = formatWithZonedDate(date, "LLLL", ptBR);
  return `${formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}, ${formatWithZonedDate(date, "yyyy", ptBR)}`;
};

/**
 * Recebe um dia da semana por parâmetro e remove ele da listagem dos dias da semana.
 * @param dayOfWeekToRemove - Dia a ser removido.
 * @returns Dias da semana sem o dia passado por parâmetro.
 */
export const filterDaysOfWeek = (dayOfWeekToRemove: number): number[] => {
  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
  return daysOfWeek.filter((day) => day !== dayOfWeekToRemove);
};

/**
 * Recebe uma data com hora e cria uma nova data com o horário atualizado.
 * @param date - Dia e hora a ser incrementado.
 * @returns Uma nova data com a atualização dos minutos.
 */
export const addMinutes = (date: Date, minutesToAdd: number): Date => {
  const newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() + minutesToAdd);
  return newDate;
};

/**
 * Converte uma string de data ISO para o fuso horário UTC e a formata no formato "yyyy-MM-dd'T'HH:mm:ss.SSS".
 *
 * @param dateString - A string de data ISO a ser convertida e formatada.
 * @returns A data formatada no fuso horário UTC no formato "yyyy-MM-dd'T'HH:mm:ss.SSS".
 *
 * @example
 * // Retorna "2024-11-28T12:00:00.000"
 * formatDateToUTC("2024-11-28T12:00:00Z");
 */
export const formatDateToUTC = (dateString: string): string => {
  const date = parseISO(dateString);
  const zonedDate = utcToZonedTime(date, "UTC");
  return formatDatefns(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const formatFromDateToMmmSlashYy = (isoDateString: string): string => {
  const date = parseISO(isoDateString);
  const month = formatDatefns(date, "MMM", { locale: ptBR });
  const year = formatDatefns(date, "yy", { locale: ptBR });
  return `${capitalizeFirstLetter(month)}/${year}`;
};

export const getFormattedMonthsArray = (fromDate: string, toDate: string): string[] => {
  const monthsList = [];
  let currentDate = parseISO(fromDate);

  while (
    isBefore(currentDate, parseISO(toDate)) ||
    currentDate.toISOString().substring(0, 10) === toDate
  ) {
    monthsList.push(
      formatFromDateToMmmSlashYy(currentDate.toISOString().substring(0, 10)),
    );
    currentDate = addMonths(currentDate, 1);
  }

  return monthsList;
};

export const getMonthDateFromFormattedString = (formattedMonth: string): Date => {
  const [monthStr, yearStr] = formattedMonth.split("/");
  const date = parse(`01/${monthStr}/${yearStr}`, "dd/MMM/yy", new Date(), {
    locale: ptBR,
  });
  return date;
};
