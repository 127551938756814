import {
  PaymentMethods,
  formatWithZonedDate,
  DatePayableStatus,
  PagarmePayableStatus,
  formatCurrencyFromCentsToReais,
  capitalizeFirstLetter,
  PayableTypes,
  removeSecondsFromTimeString,
  formatIgnoringTimezone,
} from "@repo/lib";
import {
  type ExpensumGetPayablesV2Output,
  type ExpensumPayablesV2,
  type ExpensumPayablesV2QueryQuery,
} from "@repo/graphql-types/graphql";

interface Payable {
  id: number;
  paymentDate: string;
  amount: string;
  status: DatePayableStatus;
  type: PayableTypes;
  installment: number;
  paymentMethod: PaymentMethods;
  originPaymentType: string;
  accrualAt: string;
  createdAt: string;
  installments: number;
  patientName: string;
  appointmentDate?: string | null;
  paymentCreatedAt?: string;
}

export interface DayPayables {
  date: string;
  totalAmount: { dateTotalAmountInCents: number; formattedAmount: string };
  payables: Payable[];
  dayOfWeek: string;
}

export interface PeriodPayables {
  totalAmount: number;
  dayPayables: DayPayables[];
}

// TODO: Levar toda essa lógica para o próprio endpoint do Expensum
export const mapExpensumGetPayablesResultToPeriodPayables = (
  queryResult?: ExpensumPayablesV2QueryQuery,
): PeriodPayables => {
  const expensumPayables = queryResult?.ExpensumGetPayablesV2?.data;

  const dayPayables = expensumPayables ? buildDayPayables(expensumPayables) : [];

  const periodTotalAmountInCents = dayPayables.reduce(
    (sum, dayPayable) => sum + dayPayable.totalAmount.dateTotalAmountInCents,
    0,
  );

  return {
    totalAmount: periodTotalAmountInCents,
    dayPayables,
  };
};

const buildDayPayables = (
  expensumPayables: ExpensumGetPayablesV2Output["data"],
): DayPayables[] => {
  const result = expensumPayables.map((payablesGroupedByDate) => {
    if (!payablesGroupedByDate) return undefined;

    const { date, payables: pagarmePayables } = payablesGroupedByDate;

    const dateTotalAmount = getDateTotalAmount(pagarmePayables);

    const dayOfWeek = capitalizeFirstLetter(formatWithZonedDate(date, "E, dd/MM/yyyy"));

    const payables = buildPayables(pagarmePayables);

    const dayPayables: DayPayables = {
      date: formatWithZonedDate(date),
      dayOfWeek,
      totalAmount: dateTotalAmount,
      payables,
    };

    return dayPayables;
  });

  return result.filter((payable): payable is DayPayables => payable !== undefined);
};

const getDateTotalAmount = (
  datePayables: ExpensumPayablesV2["payables"],
): { dateTotalAmountInCents: number; formattedAmount: string } => {
  const dateTotalAmountInCents = datePayables.reduce(
    (sum, payable) => sum + (payable?.amount ?? 0),
    0,
  );

  const formattedAmount = formatCurrencyFromCentsToReais(dateTotalAmountInCents).replace(/\u00A0/g, " ");

  return {
    dateTotalAmountInCents,
    formattedAmount,
  };
};

const buildPayables = (pagarmePayables: ExpensumPayablesV2["payables"]): Payable[] => {
  const payables = pagarmePayables.map((pagarmePayable) => {
    if (!pagarmePayable) return undefined;
    const paymentDate = pagarmePayable.paymentDate
      ? formatWithZonedDate(pagarmePayable.paymentDate, "dd/MM/yyyy")
      : "";
    const amount = formatCurrencyFromCentsToReais(pagarmePayable.amount ?? 0).replace(/\u00A0/g, " ");

    const paymentMethod = mapPagarmePaymentMethodToPaymentMethod(
      pagarmePayable.paymentMethod ?? "",
    );

    const status =
      pagarmePayable.status && pagarmePayable.type
        ? mapPagarmeStatus(pagarmePayable.status, pagarmePayable.type)
        : DatePayableStatus.Foreseen;

    const accrualAt = pagarmePayable.accrualAt
      ? formatWithZonedDate(pagarmePayable.accrualAt, "dd/MM/yyyy")
      : "";

    const createdAt = pagarmePayable.createdAt
      ? formatWithZonedDate(pagarmePayable.createdAt, "dd/MM/yyyy HH:mm")
      : "";

    const appointmentDate = buildAppointmentDate(
      pagarmePayable.appointmentDate ?? undefined,
      pagarmePayable.appointmentStart ?? undefined,
    );

    const paymentCreatedAt = pagarmePayable.paymentCreatedAt
      ? formatWithZonedDate(pagarmePayable.paymentCreatedAt, "dd/MM/yyyy HH:mm")
      : "";

    const type = mapPagarmePayableTypeToPayableType(pagarmePayable.type ?? "");

    const payable: Payable = {
      id: pagarmePayable.gatewayId ?? 0,
      paymentMethod,
      amount,
      status,
      type,
      installment: pagarmePayable.installment ?? 1,
      installments: pagarmePayable.installments ?? 1,
      patientName: pagarmePayable.patientName ?? "",
      originPaymentType: pagarmePayable.originPaymentType ?? "",
      paymentDate,
      accrualAt,
      createdAt,
      appointmentDate,
      paymentCreatedAt,
    };

    return payable;
  });

  return payables.filter((payable): payable is Payable => payable !== undefined);
};

export const buildAppointmentDate = (
  appointmentDate?: string,
  appointmentStart?: string,
): string | null => {
  if (appointmentDate && appointmentStart) {
    const date = formatIgnoringTimezone(new Date(appointmentDate), "dd/MM/yyyy");
    const start = removeSecondsFromTimeString(appointmentStart);
    return `${date} ${start}`;
  }

  return null;
};

export const mapPagarmeStatus = (
  pagarmeStatus: string,
  pagarmeType: string,
): DatePayableStatus => {
  const map: Record<PagarmePayableStatus, DatePayableStatus> = {
    [PagarmePayableStatus.WaitingFunds]: DatePayableStatus.Foreseen,
    [PagarmePayableStatus.Paid]: DatePayableStatus.Paid,
    [PagarmePayableStatus.Prepaid]: DatePayableStatus.Foreseen,
  };

  return pagarmeType === "refund"
    ? DatePayableStatus.Refund
    : map[pagarmeStatus as PagarmePayableStatus];
};

export const mapPagarmePaymentMethodToPaymentMethod = (
  pagarmePaymentMethod: string,
): PaymentMethods => {
  const map: Record<string, PaymentMethods> = {
    credit_card: PaymentMethods.Credit,
    debit_card: PaymentMethods.Debit,
    pix: PaymentMethods.Pix,
  };

  return map[pagarmePaymentMethod] ?? PaymentMethods.NotIdentified;
};

export const mapPagarmePayableTypeToPayableType = (
  pagarmePayableType: string,
): PayableTypes => {
  const map: Record<string, PayableTypes> = {
    credit: PayableTypes.Credit,
    refund: PayableTypes.Refund,
    refund_reversal: PayableTypes.RefundReversal,
    chargeback: PayableTypes.Chargeback,
    chargeback_refund: PayableTypes.ChargebackRefund,
  };

  return map[pagarmePayableType] ?? "";
};
