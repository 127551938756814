import { useRouter } from "@tanstack/react-router";
import { Button } from "@/components/button";

export const PayablesAbout = (): JSX.Element => {
  const router = useRouter();

  const handleClickBackButton = (): void => {
    router.history.back();
  };

  return (
    <div className="flex-col flex gap-4">
      <h1 className="font-semibold text-lg text-neutral-600">Recebimentos</h1>
      <p>Nesta tela, você pode acompanhar todos os pagamentos recebidos e a receber.</p>
      <p>
        Os valores exibidos são valores líquidos, ou seja, já deduzidos das taxas
        aplicáveis. As etiquetas indicam o status de cada recebimento (
        <b className="font-semibold text-neutral-600">Previsto</b>,{" "}
        <b className="font-semibold text-neutral-600">Liberado</b> ou{" "}
        <b className="font-semibold text-neutral-600">Estornado</b>).
      </p>

      <section className="flex-col flex gap-4">
        <h2 className="text-md font-semibold text-neutral-600">Entenda os status:</h2>

        <li>
          <span className="font-semibold text-neutral-600">Previsto:</span> O pagamento
          foi confirmado e está previsto o recebimento. A transferência será realizada no
          próximo dia útil após a data de liberação do valor.
        </li>

        <li>
          <span className="font-semibold text-neutral-600">Liberado:</span> O valor do
          pagamento foi liberado e será transferido automaticamente para sua conta
          bancária. Caso o valor não conste em sua conta, verifique os dados bancários
          cadastrados.
        </li>

        <li>
          <span className="font-semibold text-neutral-600">Estornado:</span> O pagamento
          foi estornado e o valor correspondente lançado como débito.
        </li>
      </section>

      <Button onClick={handleClickBackButton}>Voltar</Button>
    </div>
  );
};
