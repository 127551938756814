import { addMonths, isBefore, setMonth, startOfMonth } from "date-fns";
import { format } from "date-fns-tz";
import { useMemo } from "react";

/**
 * Hook usado para determinar data início dos gráficos do livance assist pro
 *
 */
export const useInitialDateForOverviews = (endOfToday: Date): string => {
  return useMemo(() => {
    let fromDate = addMonths(endOfToday, -3);

    const november2024 = new Date(2024, 10, 1);

    fromDate = isBefore(fromDate, november2024) ? setMonth(fromDate, 10) : fromDate;

    return format(startOfMonth(fromDate), "yyyy-MM-dd HH:mm:ssXXX", {
      timeZone: "America/Sao_Paulo",
    });
  }, [endOfToday]);
};
