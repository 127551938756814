import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CpsTextArea } from "corpus";
import { graphql } from "@repo/graphql-types/gql";
import { useParams, useRouter, useSearch } from "@tanstack/react-router";
import { PatientsPaymentsQueryDocument } from "@repo/graphql-types/graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { GenericSuccessDrawer } from "@/components/generic-success-drawer";
import {
  useGraphQL,
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form.tsx";

const GetOnlinePaymentInfoByGuidQuery = graphql(`
  query GetOnlinePaymentInfoByGuid($guid: uuid!) {
    onlinePayment: expensum_online_payment(where: { guid: { _eq: $guid } }) {
      id
    }
  }
`);

const InsertOnlinePaymentActivityMutation = graphql(`
  mutation InsertOnlinePaymentActivity(
    $object: expensum_online_payment_activity_insert_input!
  ) {
    insert_expensum_online_payment_activity_one(object: $object) {
      onlinePaymentId
    }
  }
`);

export const ChangePaymentStatusForm = (): JSX.Element => {
  const { guid } = useParams({
    from: "/settings/payments/patient-payments/$guid/change-payment-status",
  });

  const { action } = useSearch({
    from: "/settings/payments/patient-payments/$guid/change-payment-status",
  });

  const router = useRouter();

  const invalidatePatientsPaymentsQuery = useInvalidateQuery(
    PatientsPaymentsQueryDocument,
  );

  const queryResult = useGraphQL(GetOnlinePaymentInfoByGuidQuery, {
    guid,
  });

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    InsertOnlinePaymentActivityMutation,
  );

  const formSchema = z.object({
    note: z.string().optional(),
  });

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
  });

  const handleSuccessDrawerDismiss = (value: boolean): void => {
    if (!value && action === "success") {
      invalidatePatientsPaymentsQuery();
      router.history.go(-2);
    }
  };

  const handleErrorDrawerDismiss = (value: boolean): void => {
    if (!value && action === "error") {
      void router.navigate({ search: { action: undefined }, replace: true });
    }
  };

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    const onSuccess = (): void => {
      void router.navigate({ search: { action: "success" }, replace: true });
    };

    const onError = (): void => {
      void router.navigate({ search: { action: "error" }, replace: true });
    };

    try {
      const onlinePayment = queryResult.data?.onlinePayment[0];
      await mutateAsync(
        {
          object: {
            onlinePaymentId: onlinePayment?.id ?? 0,
            note: formData.note,
            type: "manual",
            status: "canceled",
          },
        },
        { onSuccess, onError },
      );
    } catch (error) {
      onError();
    }
  };

  return (
    <AsyncDataWrapper {...queryResult}>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <h1 className="text-neutral-600 text-xl font-medium">
                Alterar status do pagamento
              </h1>
              <p className="text-neutral-500 font-normal text-lg">
                Você está alterando manualmente o status do pagamento para ”Cancelado”.
              </p>
              <p className="text-neutral-500 font-normal text-lg">
                Você pode também adicionar uma observação abaixo para manter um registro
                de consulta da ação.
              </p>
            </div>
            <FormField
              name="note"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CpsTextArea
                      title="Observação (opcional)"
                      placeholder="Observação"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormSubmitButton>Confirmar novo status</FormSubmitButton>
          </div>
        </FormHandlerSubmit>
      </FormRoot>

      <GenericSuccessDrawer
        open={action === "success"}
        title="Status do pagamento alterado!"
        setOpen={handleSuccessDrawerDismiss}
        confirmButtonText="Voltar"
      />
      <UnexpectedErrorDrawer
        open={action === "error"}
        setOpen={handleErrorDrawerDismiss}
      />
    </AsyncDataWrapper>
  );
};
