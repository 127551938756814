import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgIncreasePeople = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M23.297 7.547h-2.813a.703.703 0 0 0 0 1.406H21.6l-3.426 3.426a4.94 4.94 0 0 0-2.532-3.333 3.508 3.508 0 0 0 1.234-2.671 3.52 3.52 0 0 0-3.516-3.516 3.52 3.52 0 0 0-3.515 3.516c0 1.068.48 2.026 1.234 2.671a4.945 4.945 0 0 0-2.45 3 4.96 4.96 0 0 0-1.423-1.126 3.509 3.509 0 0 0 1.233-2.67 3.52 3.52 0 0 0-3.516-3.516A3.52 3.52 0 0 0 1.406 8.25c0 1.068.48 2.026 1.234 2.671A4.927 4.927 0 0 0 0 15.281v5.156c0 .389.315.704.703.704h5.719a.703.703 0 0 0 .497-.206l5.128-5.128 1.846 1.847c.275.274.72.274.995 0l7.706-7.707v1.116a.703.703 0 0 0 1.406 0V8.25a.703.703 0 0 0-.703-.703M11.25 6.375c0-1.163.946-2.11 2.11-2.11 1.163 0 2.109.947 2.109 2.11 0 1.163-.946 2.11-2.11 2.11a2.112 2.112 0 0 1-2.109-2.11M2.813 8.25c0-1.163.946-2.11 2.109-2.11s2.11.947 2.11 2.11c0 1.163-.947 2.11-2.11 2.11a2.112 2.112 0 0 1-2.11-2.11M6.13 19.734H1.406v-4.453a3.52 3.52 0 0 1 3.516-3.515c.92 0 1.79.353 2.448.995a3.5 3.5 0 0 1 1.062 2.415c0 .025.002.05.006.074v2.177zm8.26-3.572-1.847-1.847a.703.703 0 0 0-.994 0l-1.706 1.706v-2.615a3.52 3.52 0 0 1 3.515-3.515 3.52 3.52 0 0 1 3.516 3.515v.271z" />
    </svg>
  );
};
export default SvgIncreasePeople;
