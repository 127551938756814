import { Graph } from "@repo/icons";

export const DashboardSectionEmptyState = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-gray-300 rounded-full p-4 w-18 h-18 flex items-center">
        <Graph className="fill-gray-500" size={24} />
      </div>
      <p className="font-normal text-neutral-500 text-sm text-center m-4">
        Sem dados no momento para exibir.
      </p>
    </div>
  );
};
