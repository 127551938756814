import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type CustomerContactDashboardSchedulingConversionRateSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";

export const CustomerContactDashboardSchedulingConversionRateSectionQueryFragment =
  graphql(/* GraphQL */ `
    fragment CustomerContactDashboardSchedulingConversionRateSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      percentCustomerContactsAppointmentsScheduled
      totalCustomerContactsNewAppointmentMainReason
      totalCustomerContactsAppointmentsScheduled
      period
    }
  `);

interface CustomerContactDashboardSchedulingConversionRateSectionProps {
  data: FragmentType<
    typeof CustomerContactDashboardSchedulingConversionRateSectionQueryFragment
  >[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardSchedulingConversionRateSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardSchedulingConversionRateSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    CustomerContactDashboardSchedulingConversionRateSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showSchedulingConversionRateDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Conversão de agendamento",
      });

      void router.navigate({ search });
    } else if (searchParams.showSchedulingConversionRateDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.totalCustomerContactsNewAppointmentMainReason !== 0,
  );

  return (
    <div className="my-8 flex flex-col gap-8">
      <DashboardSectionHeader onClick={onOpenChange}>
        Conversão de agendamento
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={currentMonthData?.percentCustomerContactsAppointmentsScheduled ?? 0}
            isPercent
          />
          <CustomerContactDashboardSchedulingConversionRateSectionChart
            data={
              fragmentData as CustomerContactDashboardSchedulingConversionRateSectionQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showSchedulingConversionRateDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Conversão de agendamento"
      >
        <p className="text-md">
          Agendamentos realizados a partir de contatos que selecionaram a opção agendar
          consulta no inicio do atendimento.
        </p>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface CustomerContactDashboardSchedulingConversionRateSectionChartProps {
  data: CustomerContactDashboardSchedulingConversionRateSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardSchedulingConversionRateSectionChart = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardSchedulingConversionRateSectionChartProps): JSX.Element => {
  const chartData = data.map((x) => {
    return {
      "Interessados em agendar": x.totalCustomerContactsNewAppointmentMainReason,
      Agendaram: x.totalCustomerContactsAppointmentsScheduled,
      mes: x.period,
    };
  });

  return (
    <div data-testid="scheduling-conversion-rate-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={chartData}
      >
        <ChartBarContainer height={299} XAxisKey="mes">
          <ChartBarItem dataKey="Agendaram" fill="#E59112" disabledFill="#D5DADF" />
          <ChartBarItem
            dataKey="Interessados em agendar"
            fill="#FFD094"
            disabledFill="#EDF0F2"
          />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
