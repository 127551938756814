import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types";
import { Left } from "@repo/icons";
import { useSuspenseGraphQL } from "@/hooks/use-graphql.ts";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header.tsx";
import { Page } from "@/components/page.tsx";
import { PendingComponent } from "@/components/pending-component.tsx";
import { SecretaryDetailsRestrictionsForm } from "@/components/secretary-details/restrictions-form";

export const GetSecretaryRestrictionsQuery = graphql(/* GraphQL */ `
  query GetSecretaryRestrictionsQuery {
    ...SecretaryDetailsRestrictionsFormFragment
  }
`);

const RestrictionsPage = (): React.ReactNode => {
  const { data } = useSuspenseGraphQL(GetSecretaryRestrictionsQuery, {});
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="" align="center" />
      </HeaderRoot>
      <Page>
        <SecretaryDetailsRestrictionsForm data={data} />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/assist/secretary-details/restrictions",
)({
  component: RestrictionsPage,
  pendingComponent: () => <PendingComponent title="" />,
});
