import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { PayablesAbout } from "@/components/payables-about";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const AboutPayablesPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Sobre" align="center" />
      </HeaderRoot>
      <Page>
        <PayablesAbout />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/payables/about")({
  component: AboutPayablesPage,
});
