import { useRouter, useSearch } from "@tanstack/react-router";
import { SingleNumberChart } from "@/components/charts/single-number-chart";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { trackEvent } from "@/lib/tracking";
import { DashboardChartSection } from "@/components/charts/dashboard-chart-section";
import { ChartLegendItem } from "@/components/charts";

interface RescuedUnscheduledAppointmentsSectionProps {
  data?: {
    month: string;
    rescuedAppointmentType: string;
    appointmentsRescuedSum?: number;
    sentMessagesSum?: number;
  };
}

export const RescuedUnscheduledAppointmentsSection = ({
  data,
}: RescuedUnscheduledAppointmentsSectionProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });

  const onClick = (): void => {
    searchParams.showRescuedUnscheduledAppointmentsDrawer = true;

    trackEvent("Definição Gráfico Aberta", {
      grafico: "Agendamentos recuperados",
    });

    void router.navigate({ search: searchParams });
  };

  const displayText =
    data?.appointmentsRescuedSum && data.appointmentsRescuedSum > 1
      ? "Agendamentos recuperados"
      : "Agendamento recuperado";

  const legendText =
    data?.sentMessagesSum && data.sentMessagesSum > 1
      ? "Mensagens enviadas"
      : "Mensagem enviada";

  if (!data) {
    return (
      <DashboardChartSection chartTitle="Agendamentos recuperados" onClick={onClick}>
        <DashboardSectionEmptyState />
        <RescuedUnscheduledAppointmentsDrawer />
      </DashboardChartSection>
    );
  }

  return (
    <DashboardChartSection chartTitle="Agendamentos recuperados" onClick={onClick}>
      <SingleNumberChart
        totalNumber={data.appointmentsRescuedSum ?? 0}
        displayText={displayText}
      />
      <ChartLegendItem
        value={data.sentMessagesSum?.toString() ?? "0"}
        displayText={legendText}
        index={1}
      />
      <RescuedUnscheduledAppointmentsDrawer />
    </DashboardChartSection>
  );
};

const RescuedUnscheduledAppointmentsDrawer = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });
  const router = useRouter();


  return (
    <DashboardInformationalDrawer
      open={Boolean(searchParams.showRescuedUnscheduledAppointmentsDrawer)}
      drawerTitle="Agendamentos recuperados"
      onOpenChange={(open) => {
        if (!open) {
          void router.navigate({
            search: {
              showRescuedUnscheduledAppointmentsDrawer: undefined,
            },
            replace: true,
          });
        }
      }}
    >
      Para pacientes que interromperam o agendamento — seja para pensar, verificar
      reembolso ou por abandonarem a conversa —, enviamos mensagens incentivando a
      conclusão. Este gráfico mostra quantos realizaram o agendamento após receberem nossa
      mensagem.
    </DashboardInformationalDrawer>
  );
};
