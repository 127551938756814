import { createFileRoute, redirect } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ensureQueryData } from "@/hooks/use-graphql";
import { ChangePaymentStatusForm } from "@/components/change-payment-status-form";

const ChangePaymentStatusPageQuery = graphql(/* GraphQL */ `
  query GetOnlinePaymentByGuid($guid: uuid!) {
    onlinePayment: expensum_online_payment(where: { guid: { _eq: $guid } }) {
      guid
    }
  }
`);

const ChangePaymentStatusPageSearchParams = z.object({
  action: z.string().optional(),
});

export const ChangePaymentStatusPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Alterar status" align="center" />
      </HeaderRoot>
      <Page>
        <ChangePaymentStatusForm />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/payments/patient-payments/$guid/change-payment-status",
)({
  component: ChangePaymentStatusPage,
  validateSearch: ChangePaymentStatusPageSearchParams,
  beforeLoad: async (opts) => {
    try {
      const variables = {
        guid: opts.params.guid,
      };

      const data = await ensureQueryData(
        opts.context,
        ChangePaymentStatusPageQuery,
        variables,
      );

      const onlinePayments = data.onlinePayment;

      if (onlinePayments.length === 0) throw new Error("Payment not found!");
    } catch (e) {
      redirect({
        to: "/settings/payments/patient-payments",
        throw: true,
      });
    }

    return {};
  },
});
