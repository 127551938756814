import { graphql } from "@repo/graphql-types/gql";
import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import {
  AppointmentChanel,
  AppointmentTypeCategoryEnum,
  formatWithZonedDate,
} from "@repo/lib";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { FollowUpAppointmentReminderForm } from "@/components/follow-up-appointment-reminder-form";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer";

const FollowUpAppointmentReminderPageCreateQuery = graphql(/* GraphQL */ `
  query FollowUpAppointmentReminderPageCreateQuery(
    $codAgendamento: Int!
    $canalAgendamentoPaciente: Int!
    $categoriaPeriodoGarantido: Int!
    $dataInicio: date!
  ) {
    ...FollowUpAppointmentReminderFormFragment
    appointment: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      nome
      Usuario {
        apelido
        nome
        sobrenome
      }
      UsuarioCompromisso {
        nome
      }
    }
  }
`);

export const FollowUpAppointmentReminderCreatePage = (): JSX.Element => {
  const { appointmentId } = Route.useParams();
  const searchParams: Record<string, unknown> = useSearch({ strict: false });
  const router = useRouter();

  const queryResult = useGraphQL(FollowUpAppointmentReminderPageCreateQuery, {
    codAgendamento: Number(appointmentId),
    canalAgendamentoPaciente: AppointmentChanel.Paciente.valueOf(),
    categoriaPeriodoGarantido: AppointmentTypeCategoryEnum.PeriodoGarantido.valueOf(),
    dataInicio: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
  });

  const { data } = queryResult;
  const isDrawerOpen = searchParams.action === "about";

  const setOpen = (isOpening: boolean): void => {
    if (!isOpening && isDrawerOpen) {
      router.history.back();
    }
  };

  const appointment = data?.appointment;

  function getUserNickname(): string {
    if (appointment?.Usuario?.apelido) {
      return appointment.Usuario.apelido;
    }

    const name = appointment?.Usuario?.nome ?? "";
    const lastName = appointment?.Usuario?.sobrenome ?? "";

    return `${name} ${lastName}`;
  }

  const openAboutDrawer = (): void => {
    void router.navigate({
      search: {
        ...searchParams,
        action: "about",
      },
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Lembrete de retorno" align="center" />
        <HeaderButton icon={HelpCircle} align="end" onClick={openAboutDrawer} />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <FollowUpAppointmentReminderForm data={data} /> : null}
          <TooltipDrawerRoot
            open={isDrawerOpen}
            setOpen={(isOpening) => {
              setOpen(isOpening);
            }}
          >
            <TooltipDrawerTitle>Modelo da mensagem</TooltipDrawerTitle>
            <TooltipDrawerBody>
              <div className="mt-2 flex flex-col gap-2">
                <p>Olá, {appointment?.nome}.</p>
                <p>
                  {getUserNickname()} gostaria de te lembrar que está na hora de agendar
                  seu próximo atendimento.
                </p>
                <p>
                  Selecione a opção: {appointment?.UsuarioCompromisso?.nome} e confira os
                  horários disponíveis.
                </p>
                <p>Estamos esperando por você.</p>
              </div>
            </TooltipDrawerBody>
          </TooltipDrawerRoot>
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/waiting-room/follow-up-appointment-reminder/$appointmentId",
)({
  component: FollowUpAppointmentReminderCreatePage,
});
